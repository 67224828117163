import React, { useEffect, useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import reactStringReplace from 'react-string-replace';
import PageNum from './PageNum';
import SoundButton from './SoundButton';
import { isVowel } from '../../utils/pm/util';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
let bookNameUnitStage = undefined;
let bookName4bUnitReviewStage2 = undefined;
let bookNameUnitStageChecklistkey = {};
let bookNameUnitStageChecklist = {};
let urlbookname = undefined;
let urlbookunitname = undefined;
let urlstagename = undefined;
const ImgDnD4 = ({ steps, setSteps, hasSoundBtn, hideWordBox, directionEnd, problems }) => {
  const safeSteps = steps < problems.length ? steps : problems.length - 1;
  const problem = problems[safeSteps];

  const rightWord = !_.isNil(problem.rightWord) ? problem.rightWord : problem.question + problem.answer;
  const questionFormat = !_.isNil(problem.questionFormat)
    ? problem.questionFormat
    : !_.isNil(problem.question)
    ? `##${problem.question}##${problem.answer}`
    : problem.answer;

  const [wordSound, setWordSound] = useState(undefined);
  const [dropSound, setDropSound] = useState(undefined);
  const [isCorrect, setIsCorrect] = useState(false);
  const [diplayvalue, setDisplayValue] = useState('');
  const [displayoo, setDisplayoo] = useState('oo');

  const [wordSound2, setWordSound2] = useState(undefined);
  const [wordSound2Temp, setWordSound2Temp] = useState(undefined);
  const [dropSound2, setDropSound2] = useState(undefined);
  const { level, unit, stage } = useParams();
  const disply_oo_arr = [
    { key: 'zoo', value: 'long' },
    { key: 'moose', value: 'long' },
    { key: 'cookie', value: 'short' },
    { key: 'book', value: 'short' },
  ];
  const location = useLocation();
  const resoo = location.pathname.split('/');

  urlbookname = level;
  urlbookunitname = unit;
  urlstagename = stage;
  if (level == '2a' && unit == 5 && (stage == 1 || 2 || 5)) {
    bookNameUnitStage = true;
  }

  if (level == '4a' && unit == 'R' && stage == 2) {
    bookNameUnitStageChecklist = { giraffe: 'g', bicycle: 'c', gum: 'g', candy: 'c' };
    bookNameUnitStageChecklistkey = { giraffe: 0, bicycle: 2, gum: 0, candy: 0 };
    bookName4bUnitReviewStage2 = true;
  }

  const handleWordSoundEnd = () => {
    setWordSound(undefined);
    if (isCorrect === true) {
      setSteps(steps + 1);
      setIsCorrect(false);
    }
  };
  const handleDropSoundEnd = () => {
    if (isCorrect === true) {
      //const word = problem.question + problem.answer;
      setWordSound(rightWord);
    }
    setDropSound(undefined);
  };

  const handleDragEnd = useCallback((result) => {
    const { destination } = result;
    console.log('result====>zzzz', result);
    if (_.isNil(destination) || destination.droppableId != 'image_drop_area') {
      setDropSound('wrong_sound1');
      return;
    }
    setDropSound('mouse-click');
    setIsCorrect(true);
  }, []);

  const handleDragEndForLevel1 = useCallback((result) => {
    const {
      destination,
      source: { droppableId },
    } = result;
    console.log('result', droppableId);

    if (_.isNil(destination) || destination.droppableId != 'image_drop_area') {
      setDropSound2('wrong_sound1');
      return;
    }
    setDropSound2('mouse-click');
    setIsCorrect(true);

    const mp3word = droppableId.split('_')[1].replace('.png', '');
    setWordSound2Temp(`${mp3word.split('')[0]}_${mp3word}`);

    // if (!_.isNil(destination)) {
    // 	setIsCorrect(true);
    // }
  }, []);

  const handleWordSoundEnd2 = () => {
    setWordSound2(undefined);

    if (isCorrect === true) {
      setSteps(steps + 1);
      setIsCorrect(false);
    }

    // if (isCorrect) {
    // 	setDropSound2("great");
    // } else {
    // 	setDropSound2("woops");
    // }
  };

  const handleDropSoundEnd2 = () => {
    setDropSound2(undefined);
    if (isCorrect === true) {
      setWordSound2(wordSound2Temp);
    }
    // if (isCorrect === true) {
    // 	setSteps(steps + 1);
    // 	setIsCorrect(false);
    // }
  };

  useEffect(() => {
    if (directionEnd && hasSoundBtn) {
      //const word = problem.question + problem.answer;
      setWordSound(rightWord);
    }
  }, [directionEnd, hasSoundBtn, rightWord]);

  useEffect(() => {
    if (rightWord) {
      disply_oo_arr.map((k, v) => {
        return k.key === rightWord ? setDisplayValue(k.value) : '';
      });
    }
  }, [rightWord]);
  return (
    <>
      <DragDropContext onDragEnd={!_.isNil(problem.alphabet) ? handleDragEndForLevel1 : handleDragEnd}>
        <S.ColumnFlex style={{ alignSelf: 'stretch', flex: '1 1' }} id="lv3_review2_content" className="main_content  row2_arrow">
          <PageNum
            num={safeSteps + 1}
            total={problems.length}
            style={{ top: '1.5rem', position: 'absolute', right: '1.7rem', fontSize: '1.3rem', fontWeight: 'bold' }}
          />
          <S.Dnd4TopBox
            style={{ flexDirection: 'row' }}
            className={resoo[2] === '4b' && resoo[4] == 'R' && resoo[6] == '2' ? 'cust-short-long top_box' : 'top_box'}
          >
            {hasSoundBtn && (
              <SoundButton src={`${rightWord}.mp3`} style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '3' }} />
            )}

            <ImageDropArea type={rightWord} isCorrect={isCorrect} imgUrl={problem.url} />
            <S.Dnd4BlueBoxWrapM className="blue-box-wrap-m">
              {!_.isNil(problem.alphabet) && (
                <S.Dnd4WordWrap className="word_wrap" style={{ paddingRight: '8px' }}>
                  {problem.alphabet == 'Li' ? (
                    <span style={{ color: 'red' }}>LI</span>
                  ) : (
                    <span style={{ color: 'red' }}>{problem.alphabet}</span>
                  )}
                </S.Dnd4WordWrap>
              )}
              {_.isNil(hideWordBox) && _.isNil(problem.alphabet) && (
                <>
                  <WordBoxArea questionFormat={questionFormat} isCorrect={isCorrect} answer={rightWord} />
                  {diplayvalue ? (
                    <>
                      <span style={{ position: 'absolute', left: '20px', margin: 0, padding: 0 }} className="p-relative">
                        <BlueBox class="alphabet_box row6 blue click  cust-blue">
                          <span style={{ color: '#666' }} class="font_dark">
                            {diplayvalue}
                          </span>
                          <span style={{ color: '#2e86de', marginLeft:5 }} class="font_blue">
                            {displayoo}
                          </span>
                        </BlueBox>
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}
            </S.Dnd4BlueBoxWrapM>
          </S.Dnd4TopBox>
          <Arrow src="/images/pm/i_arrow_top.svg" alt="위로 향하는 화살표" />
          <S.Dnd4BottomBox className="bottom_box">
            <S.Dnd4DragArea className="img_box  drag_area">
              {_.map(problem.candidate, (item, index) => (
                <Droppable key={index} droppableId={`drag${item}`} isDropDisabled={true} type={item.split('/image_')[1].split('.png')[0]}>
                  {(provided) => (
                    <li key={index} ref={provided.innerRef}>
                      <Draggable key={index} draggableId={`${item}${index}`} index={index}>
                        {(provided, snapshot) => {
                          const style = {
                            visibility: `${isCorrect && item.split('/image_')[1].split('.png')[0] === rightWord ? 'hidden' : 'visible'}`,
                            borderColor: snapshot.isDragging ? '#feca57' : undefined,
                            ...provided.draggableProps.style,
                          };
                          return (
                            <S.Dnd4DragItem
                              className="drag_item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={index}
                              style={style}
                            >
                              <img style={{ height: '21vh' }} src={item} alt="드래그 앤 드롭 이미지" />
                            </S.Dnd4DragItem>
                          );
                        }}
                      </Draggable>
                      <div style={{ display: 'none' }}>{provided.placeholder}</div>
                    </li>
                  )}
                </Droppable>
              ))}
            </S.Dnd4DragArea>
          </S.Dnd4BottomBox>
        </S.ColumnFlex>
      </DragDropContext>
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      <SoundEffectPM
        url={!_.isNil(wordSound) ? `https://cdn.cloubot.com/PM/audio/sounds/${wordSound}.mp3` : undefined}
        onEnd={handleWordSoundEnd}
      />

      <SoundEffectPM
        url={!_.isNil(dropSound2) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound2}.mp3` : undefined}
        onEnd={handleDropSoundEnd2}
      />
      <SoundEffectPM
        url={!_.isNil(wordSound2) ? `https://cdn.cloubot.com/PM/audio/sounds/${wordSound2}.mp3` : undefined}
        onEnd={handleWordSoundEnd2}
      />
    </>
  );
};

export default ImgDnD4;
const Arrow = styled.img`
  height: 2.4rem;
  margin: 1rem 0;
  @media (max-height: 500px) {
    height: 1rem;
    margin: 0.1rem 0;
  }
`;
const BlueBox = styled(S.RowFlex)`
  top: -8px;
  left: 0;
  width: 216px;
  font-size: 28px;
  /* height: 36px; */
  position: absolute;
  margin-left: 4px;
  border-radius: none;
  background-color: #fff;
  border: 6px solid #48dbfb;
  box-shadow: 0 6px 0 #0abde3;
  justify-content: center;
  align-items: center;
  margin-top: -6px;
  padding: 2px;
  font-weight: 600;
  @media (max-height: 500px) {
    top: 3px;
    font-size: 15px;
    margin-top: -3px;
    
    border: 4px solid #48dbfb;
    box-shadow: 0 4px 0 #0abde3;
  }
`;
const ImageDropArea = ({ type, imgUrl, isCorrect }) => {
  return (
    <S.Dnd4DropArea className="img_box drop_area">
      <Droppable droppableId={`image_drop_area`} type={type}>
        {(provided, snapshot) => (
          <S.Dnd4DropLi
            ref={provided.innerRef}
            style={{ backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined, borderColor: isCorrect ? '#feca57' : undefined }}
          >
            <S.RowFlex className="drag_item" style={{ justifyContent: 'center' }}>
              {/* <img src={imgUrl} alt={imgUrl} style={{display: isCorrect ? undefined : "none"}} /> */}
              <img src={imgUrl} alt={imgUrl} style={{ height: '21vh', visibility: isCorrect ? undefined : 'hidden' }} />
              <div style={{ display: 'none' }}>{provided.placeholder}</div>
            </S.RowFlex>
          </S.Dnd4DropLi>
        )}
      </Droppable>
    </S.Dnd4DropArea>
  );
};

const WordBoxArea = ({ questionFormat, answer, isCorrect }) => {
  const getClassNameException = (hex = false) => {
    if (['2'].includes(urlstagename.toString()) && ['3a', '3b'].includes(urlbookname)) {
      return 'font_blue';
    }
    return 'font_black';
  };
  const replaceText = reactStringReplace(questionFormat, /##(\w+)##/g, (match) =>
    bookNameUnitStage ? (
      <S.ImgDnd4AlphabetBox
        isLong={match.length > 2}
        className={`alphabet_box color row1 ${isCorrect ? 'yellow' : 'gray'} ${match.length > 2 ? 'long-long-width' : ''}`}
      >
        <span style={{ color: '#2e86de' }} className="font_blue">
          {isCorrect && match}
        </span>
      </S.ImgDnd4AlphabetBox>
    ) : (
      <S.ImgDnd4AlphabetBox
        isLong={match.length > 2}
        className={`alphabet_box color row1 ${isCorrect ? 'yellow' : 'gray'} ${match.length > 2 ? 'long-long-width' : ''}`}
      >
        <span style={{ color: getClassNameException(true) }} className={`${getClassNameException()}`}>
          {isCorrect && match}
        </span>
      </S.ImgDnd4AlphabetBox>
    ),
  );
  const hasObject = replaceText && replaceText.some((item) => (_.isObject(item) ? true : false));
  return (
    <S.Dnd4WordWrap className="word_wrap" style={{ paddingLeft: '2rem', color: 'blue' }}>
      {hasObject
        ? replaceText &&
          _.map(replaceText, (item) => {
            return _.isObject(item)
              ? item
              : bookName4bUnitReviewStage2
              ? _.map(item, (item2, key2) => (
                  <span
                    data="first"
                    key={`alpha-key-${key2}`}
                    className={item2 !== '' ? 'font_pink' : 'font_blue'}
                    style={{ color: item2 !== '' ? '#f368e0' : '#2e86de' }}
                  >
                    {item2}
                  </span>
                ))
              : _.map(item, (item2, key2) => (
                  <span
                    data="first"
                    key={`alpha-key-${key2}`}
                    style={{ color: item2 !== '' ? '#f368e0' : '#2e86de' }}
                    className={item2 !== '' ? 'font_pink' : 'font_blue'}
                  >
                    {item2}
                  </span>
                ));
          })
        : isCorrect
        ? bookName4bUnitReviewStage2
          ? _.map(answer, (item3, key3) => (
              <span
                data="second"
                key={`alpha-key3-${key3}`}
                style={{
                  color:
                    bookNameUnitStageChecklist[answer] == item3 && bookNameUnitStageChecklistkey[answer] == key3 ? '#f368e0' : '#2e86de',
                }}
                className={
                  bookNameUnitStageChecklist[answer] == item3 && bookNameUnitStageChecklistkey[answer] == key3 ? 'font_pink' : 'font_blue'
                }
              >
                {item3}
              </span>
            ))
          : _.map(answer, (item3, key3) => (
              <span
                data="second"
                key={`alpha-key3-${key3}`}
                style={{ color: isVowel(item3) ? '#ee5353' : '#2e86de' }}
                className={isVowel(item3) ? 'font_red' : 'font_blue'}
              >
                {item3}
              </span>
            ))
        : questionFormat}
    </S.Dnd4WordWrap>
  );
};
