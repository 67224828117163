import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import StudyResultListPresenter from 'src/components/StudyResult/StudyResultListPresenter';
import { USER_CLASS_LESSON_LIST, BOOK_STAGE_LIST_BY_BOOK_CODE } from 'src/operations/queries/study';
import { GET_USER_AI_CHAT_LOG } from 'src/operations/queries/getaichat';
import SpeakingTestResultModal from 'src/components/Modal/SpeakingTestResultModal';
import { getStageTableHeader } from 'src/utils/bookStage';
import { isAfterSchool, parseBookCode } from 'src/utils';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import { userInfoVar } from 'src/apollo/cache';
import { GET_USER_LESSONS_FOR_HOMEWORK } from 'src/operations/queries/getClass';
import { settings } from 'src/dummy/settings';
import AIChatModal from '../Modal/AIChatModal';
import StageDateModal from '../Modal/StageDateModal';
import { Tooltip } from 'antd';
const isBelongToStageGroup = (stageArray, stage) => {
  return stageArray.includes(stage);
};

const getGrade = (stageGroupCode, stages, userStudyList, doSpeakingTestModal, openRecordingDataModal, doAIChatModal, companyName) => {
  if (!userStudyList || userStudyList.length === 0) {
    return <span style={{ color: '#ff7f00' }}>{settings[companyName].notPerform}</span>;
  }
  const findItem = stages.find((stage) => stage.group_code === stageGroupCode);
  if (!findItem) {
    return '오류1'; //여기로 들어오면 안 되는 거임
  }
  const findItem2 = userStudyList.find((studyItem) => studyItem.stage_no === findItem.no);
  if (!findItem2) {
    return <span style={{ color: '#ff7f00' }}>{settings[companyName].notPerform}</span>;
  }
  const { exam_total, exam_correct } = findItem2;
  if (findItem.ai_stage_code) {
    return (
      <Tooltip title={'AI Chat Log 확인하기'}>
        <span
          style={{ cursor: 'pointer', color: '#289428' }}
          onClick={() => {
            doAIChatModal(findItem, findItem2);
          }}
        >
          {settings[companyName].perform}
        </span>
      </Tooltip>
    );
  }
  if (!exam_total && !findItem.ai_stage_code) {
    return <span style={{ color: '#289428' }}>{settings[companyName].perform}</span>;
  }

  const isSpeakingTest = findItem.name === 'Speaking Test';
  const isSpeedReading = findItem.name === 'Speed Reading' || findItem.name === 'S';

  const percentGrade = Math.round((100 * parseInt(exam_correct)) / parseInt(exam_total));
  return (
    <span
      style={isSpeakingTest || isSpeedReading ? { cursor: 'pointer' } : {}}
      onClick={() => {
        if (isSpeakingTest) {
          doSpeakingTestModal(findItem2.lesson_code);
        }
        if (isSpeedReading) {
          const userName = userInfoVar()?.name;
          openRecordingDataModal(
            {
              ...findItem2,
              accuracy: `${percentGrade}%`,
            },
            userName,
          );
        }
      }}
    >
      <span style={{ color: '#289428' }}>{percentGrade || 0}</span>/<span style={{ color: '#289428' }}>100</span>
    </span>
  );
};

const columns = [
  {
    key: 'no',
    title: 'No',
    dataIndex: 'no',
    width: 50,
    align: 'left',
    sorter: (a, b) => a.no > b.no,
  },
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    align: 'left',
    width: 100,
  },
  {
    key: 'title',
    title: 'Title',
    width: 220,
    dataIndex: 'title',
    align: 'left',
    render: (text) => {
      return <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{text}</div>;
    },
  },
];

const headercolumns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
  },
  {
    key: 'class_name',
    title: '반명',
    dataIndex: 'class_name',
    align: 'center',
    sorter: true,
  },
  {
    key: 'book',
    title: '교재정보',
    dataIndex: 'book',
    align: 'center',
    sorter: true,
  },
  {
    key: 'student_name',
    title: '학생명',
    dataIndex: 'student_name',
    align: 'center',
    sorter: true,
  },
  {
    key: 'lesson_count',
    title: (
      <>
        학습 <br />
        Lesson 수
      </>
    ),
    dataIndex: 'lesson_count',
    align: 'center',
  },
  {
    key: 'complete',
    title: '수행율',
    dataIndex: 'complete',
    align: 'center',
    render: (text) => <Tooltip title={'학생의 최초 학습일부터 현재까지의 수행율입니다.'}>{text}</Tooltip>,
  },
  {
    key: 'average',
    title: '평균점수',
    dataIndex: 'average',
    align: 'center',
  },
  {
    key: 'recent_study_date',
    title: '최근학습일',
    dataIndex: 'recent_study_date',
    align: 'center',
  },
  {
    key: 'recent_study_info',
    title: '최근학습정보',
    dataIndex: 'recent_study_info',
    align: 'center',
  },
];

const StudyResultListContainer = ({ class_idx, user_idx, company_book_display, user_name = null, student_id = null }) => {
  const companyName = useSelector(classStoreData);
  const [isSpeakingVisible, setIsSpeakingVisible] = useState(false);
  const [lessonCode, setLessonCode] = useState();
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [currentActiveBookCode, setCurrentActiveBookCode] = useState(0);
  const { campus_idx, campus, id: userid } = userInfoVar();
  const pathname = window.location.pathname;
  const user_id = pathname.substring(pathname.lastIndexOf('/') + 1);
  //
  const [isAIChatVisible, setIsAIChatVisible] = useState(false);
  const [chatStage, setChatStage] = useState(null);
  const [isStageDateVisible, setIsStageDateVisible] = useState(false);
  const [stageDate, setStageDate] = useState(null);
  //
  const { data: dataLessons, loading: loadinLessonList } = useQuery(USER_CLASS_LESSON_LIST, {
    skip: !class_idx || !user_idx,
    variables: { class_idx: parseInt(class_idx), user_idx: parseInt(user_idx) },
    fetchPolicy: 'no-cache',
  });
  const className = useMemo(() => {
    if (dataLessons) {
      return dataLessons?.getUserClassLessonList[0]?.class?.name;
    }
    return '';
  }, [dataLessons]);
  const useElibrary = useMemo(() => {
    const bookDisplay = company_book_display;
    if (isAfterSchool(companyName)) {
      return false;
    }
    if (bookDisplay && Array.isArray(bookDisplay)) {
      return bookDisplay.map((item) => item.book_code).includes('EL');
    }

    return true;
  }, [company_book_display, companyName]);

  const bookCodeList = useMemo(() => {
    if (dataLessons) {
      return Object.values(
        dataLessons?.getUserClassLessonList.reduce((p, item) => {
          if (item?.book_lesson?.book && !Object.keys(p).includes(item.book_lesson?.book?.code)) {
            p[item.book_lesson.book.code] = item.book_lesson.book;
          }
          return p;
        }, {}),
      );
    }
    return [];
  }, [dataLessons]);

  const bookCode = useMemo(() => {
    if (bookCodeList.length) {
      return bookCodeList[currentActiveBookCode].code;
    }
    return undefined;
  }, [bookCodeList, currentActiveBookCode]);

  const { data: dataStages } = useQuery(BOOK_STAGE_LIST_BY_BOOK_CODE, {
    skip: !bookCode,
    variables: { book_code: bookCode },
  });
  const realColums = useMemo(() => {
    if (dataStages) {
      const { level } = parseBookCode(bookCode);
      const added = getStageTableHeader(companyName, dataStages.bookStageListByBookCode, level);
      if (useElibrary) {
        added.push({ key: 'elib', title: 'e-Lib', dataIndex: 'elib', align: 'center' });
      }
      added.push({
        key: 'study_date',
        title: '학습기록',
        dataIndex: 'study_date',
        align: 'center',
        render: (_, record) => {
          const list = record.study_date;
          return list.length > 0 ? (
            <Tooltip title={'Stage별 완료시간 확인하기'}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  doStageDateModal(list);
                }}
              >
                {list[list.length - 1].date}
              </span>
            </Tooltip>
          ) : (
            <>-</>
          );
        },
      });
      // added.push({ key: 'complete_date', title: '완료일', dataIndex: 'complete_date', align: 'center' });

      return [...columns, ...added];
    }
    return columns;
  }, [dataStages, useElibrary, companyName, bookCode]);
  const doStageDateModal = (data) => {
    setStageDate(data);
    setIsStageDateVisible(true);
  };
  const doSpeakingTestModal = (lesson_code) => {
    setLessonCode(lesson_code);
    setIsSpeakingVisible(true);
  };
  const getPercentGrade = (rightCount, totalCount) => {
    if (totalCount < rightCount) return `100%`;
    if (rightCount && totalCount) {
      return `${Math.round((100 * rightCount) / totalCount)}%`;
    }
    return `0%`;
  };
  //
  const doAIChatModal = (findItem, findItem2) => {
    setChatStage(findItem.ai_stage_code);
    setLessonCode(findItem2.lesson_code);
    setIsAIChatVisible(true);
  };
  const list = useMemo(() => {
    if (dataLessons && dataStages && bookCode) {
      return dataLessons.getUserClassLessonList
        .filter((item) => bookCode === item?.book_lesson?.book?.code)
        .sort((a, b) => {
          if (moment(a?.study_date).isAfter(b?.study_date)) {
            return 1;
          }
          if (moment(a?.study_date).isBefore(b?.study_date)) {
            return -1;
          }
          return 0;
        })
        .map((item, key) => {
          const title =
            item.book_lesson.title ||
            `${item.book_lesson?.book.book_sub[0]?.title} Unit${item.book_lesson?.unit_no}, Lesson${item.book_lesson?.day_no}`;
          const isSmartEclass = item.book_lesson?.book.parent_code === 'SM' || item.book_lesson?.book.parent_code === 'SK';
          const isTalkingTap = item.book_lesson?.book.parent_code === 'TT';
          const [tmp, unique, userStudy] = [
            item.user_study.map((e) => ({
              stage_no: e.stage_no,
              date: moment(e.study_date).format('YYYY-MM-DD HH:mm'),
            })),
            {},
            [],
          ];
          for (let i = 0; i < tmp.length; i++) {
            const stage = tmp[i];
            unique[stage.stage_no] = stage;
          }
          for (const stage of Object.values(unique)) {
            userStudy.push(stage);
          }
          const baseData = {
            key: `lesson-list-${key}`,
            no: key + 1,
            date: moment(item.study_date).format('yy-MM-DD'),
            lesson_code: item.book_lesson.code,
            title: isSmartEclass
              ? title
              : isTalkingTap
              ? `Talking Tap U${item.book_lesson?.unit_no}L${item.book_lesson?.day_no}`
              : `${item.book_lesson.code.includes('ELT') ? '' : item.book_lesson.code}${
                  item.book_lesson.title ? (item.book_lesson.code.includes('ELT') ? '' : '/') + item.book_lesson.title : ''
                }`,
            complete_date: item?.complete_date ? moment(item?.complete_date).format('YYYY-MM-DD HH:mm') : '-',
            study_date: userStudy,
          };

          for (const stageItem of dataStages.bookStageListByBookCode) {
            const groupCodes = stageItem.book_stage.map((stage) => stage.group_code);
            if (isBelongToStageGroup(groupCodes, item.book_lesson.stage_group_code)) {
              const grade = getGrade(
                item.book_lesson.stage_group_code,
                stageItem.book_stage,
                item.user_study.filter((study) => study.user_lesson_idx),
                doSpeakingTestModal,
                openRecordingDataModal,
                doAIChatModal,
                companyName,
              );
              baseData[stageItem.name_abbr] = grade;
            } else {
              baseData[stageItem.name_abbr] = <span style={{ color: '#898888' }}>--</span>;
            }
          }
          return baseData;
        });
    }
    return [];
  }, [dataLessons, dataStages, bookCode]);
  const { data: dataUserLessons, loading } = useQuery(GET_USER_LESSONS_FOR_HOMEWORK, {
    variables: {
      skip: !campus_idx,
      campus_idx: campus_idx,
      class_idx: parseInt(class_idx),
    },
  });
  const dataList1 = useMemo(() => {
    const list = dataUserLessons?.getUserLessonsCampus?.user_lessons;
    if (list) {
      return list.map((item, key) => {
        const bookTitle = item?.class?.book?.book_sub[0]?.title || item?.class?.book_code;
        return {
          // No: PER_PAGE * searchValue.pageNumber + key + 1,
          No: key + 1,
          key: `user-lesson-row${key}`,
          class_name: item?.class?.name,
          class_idx: item?.class?.idx,
          book: bookTitle,
          student_name: item?.user?.name,
          lesson_count: `${item?.homework_result?.complete_count}/${item?.lesson_count}`,
          complete: isAfterSchool(companyName)
            ? getPercentGrade(item?.homework_result?.complete_count, item?.lesson_count)
            : getPercentGrade(
                item?.homework_result?.complete_count,
                item?.class?.class_lesson?.filter((ele) => {
                  return (
                    moment().isSameOrAfter(ele.study_date) &&
                    moment.utc(ele.study_date).isSameOrAfter(item?.homework_result?.oldest_info?.date)
                  );
                })?.length,
              ), //오늘 날짜 기준 학습 수행율

          average: item?.homework_result?.average,
          recent_study_date: item?.homework_result?.latest_info?.date
            ? moment(item?.homework_result?.latest_info?.date).format('YYYY-MM-DD HH:mm')
            : '없음',
          recent_study_info: item?.homework_result?.latest_info?.lesson
            ? item?.homework_result?.latest_info?.lesson.slice(-4).replace('U', 'Unit').replace('D', ' Lesson')
            : '없음',
          user_idx: item?.user?.idx,
          complete_count: item?.homework_result?.complete_count,
          // history: history,
        };
      });
    }
    return [];
  }, [dataUserLessons, companyName]);
  const classdata = dataList1.filter((item) => item.class_idx == class_idx && item.user_idx == user_id);

  const handleSpeakingCancel = () => {
    setIsSpeakingVisible(false);
    setLessonCode(undefined);
  };

  function openRecordingDataModal(info, nameStr) {
    setIsRecordingVisible(true);
    //setRecordingContent(info?.recording_data);
    setRecordingData(info);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }
  useEffect(() => {
    if (list.length) {
      setLessonCode(list[0].lesson_code);
    }
  }, [list]);
  const handleAiChatCancel = () => {
    setIsAIChatVisible(false);
    setLessonCode(undefined);
  };
  const handleStageDateCancel = () => {
    setIsStageDateVisible(false);
    setStageDate(null);
  };

  // AI Chat Log temp for ESL
  const { data: chatData, loading: loadingChatData } = useQuery(GET_USER_AI_CHAT_LOG, {
    skip: !user_id || !lessonCode || !chatStage,
    variables: {
      user_id: student_id ?? userid,
      lesson: lessonCode,
      stage: chatStage,
      app_id: lessonCode?.startsWith('ESL-') ? 'choisun_esl' : campus.company.ai_app_id,
    },
    fetchPolicy: 'no-cache',
  });
  //
  return (
    <>
      <StudyResultListPresenter
        title={user_name ? user_name : `Class: ${className} Result`}
        data={list}
        columns={realColums}
        loading={loading || loadinLessonList}
        classdata={classdata}
        classcolumns={headercolumns}
        bookCodeList={bookCodeList}
        currentActiveBookCode={currentActiveBookCode}
        onClickTab={setCurrentActiveBookCode}
      />
      <SpeakingTestResultModal
        user_idx={parseInt(user_idx)}
        lesson_code={lessonCode}
        visible={isSpeakingVisible}
        onCancel={handleSpeakingCancel}
      />
      <RecordingDataModal
        data={{ ...recordingData, currentUserName, className }}
        isShow={isRecordingVisible}
        onPrint={null}
        componentRef={null}
        onOk={handleOkForRecordingData}
        hidePrint={true}
      />
      <AIChatModal
        visible={isAIChatVisible}
        onCancel={handleAiChatCancel}
        // user_idx={parseInt(user_idx)}
        chatData={chatData?.getUserAiChatLog}
      />
      <StageDateModal visible={isStageDateVisible} onCancel={handleStageDateCancel} stageDate={stageDate} />
    </>
  );
};

export default StudyResultListContainer;
