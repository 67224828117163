import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
//import MyScriptHook from "./MyScriptHook";
// import {SmallScripter} from "./Scripter";
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PopUp from './PopUp';
import Keyboard from './Keyboard';
import { useLocation } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const correctSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/game-win-notify.mp3';
const wrongSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/wrong-error-sound.mp3';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
const MAX_WRONG_COUNT = 3;

const MultiReviewType = ({ stages, steps, setSteps, onFinish }) => {
  const {
    customData: { problem },
  } = stages;
  // 여기에 유저가 적은 답이 표시된다...
  const [answer, setAnswer] = useState([]);
  const [wrongCount, setWrongCount] = useState(0);
  const [getKey, setGetKey] = useState([]);

  const [correctCheck, setCorrectCheck] = useState([]);
  const [clickSound, setClickSound] = useState('');

  const [selectedWord, setSelectedWord] = useState(undefined);
  const location = useLocation();
  const resvowel = location.pathname.split('/');
  // const totalCount = stages.customData.totalCount;

  const safeSteps = steps > problem.length - 1 ? problem.length - 1 : steps;
  const handleSoundEnd = () => {
    if (answer.length === 4) {
      setSteps((steps) => steps + 1);
    }
    // }
    setClickSound('');
  };
  const answerList = useMemo(() => {
    const answerArray = [];
    if (steps < 2) {
      Object.keys(problem[steps].exImage).forEach((inner) => {
        if (problem[steps].exImage[inner].isAnswer) {
          answerArray.push(problem[steps].exImage[inner].image.split('/image_')[1].split('.png')[0]);
        }
      });
    }
    return answerArray;
  }, [problem, steps]);
  const handleClickImg = (val) => {
    if (answer.includes(val)) {
      return;
    } else {
      if (answerList.includes(val)) {
        setSelectedWord(val);
        // setClickSound(correctSound);
      } else {
        setClickSound(woopsSound);
      }
    }
  };
  const handleWriteEnd = () => {
    const userAnswer = getKey.join('');
    if (userAnswer === selectedWord) {
      setAnswer([...answer, selectedWord]);
      setClickSound(correctSound);
      setSelectedWord(undefined);
    } else {
      setClickSound(woopsSound);

      setWrongCount((prev) => prev + 1);
    }
  };
  useEffect(() => {
    if (wrongCount === MAX_WRONG_COUNT) {
      setAnswer([...answer, selectedWord]);
      setTimeout(() => {
        setWrongCount(0);
        setSelectedWord(undefined);
      }, 3000);
    }
  }, [wrongCount]);
  useEffect(() => {
    if (correctCheck.every((v) => v)) {
      setCorrectCheck([]);
      setAnswer([]);
    } else {
      setCorrectCheck([...correctCheck]);
      setAnswer([...answer]);
    }
    if (steps >= problem.length) {
      onFinish();
    }
    // eslint-disable-next-line
  }, [steps]);
  return (
    <>
      <S.ColumnFlex style={{ height: 'calc(100% - 70px)', position: 'relative' }}>
        <Wrap
          selectedWord={selectedWord}
          // style={{ padding: '1.1rem 1.2rem', alignSelf: 'stretch', flex: '1 1' }}
          id="lv3_review7_content"
          className={(resvowel[2] === '2a' || resvowel[2] === '2b') && resvowel[6] == '7' ? 'cust-vowel main_content' : 'main_content'}
        >
          <TitleTxt>
            Step {safeSteps + 1}. {stages.customData.problem[safeSteps].exVowel[0]} Vowel -{' '}
            <strong style={{ color: '#ee5353' }} className="font_red">
              {stages.customData.problem[safeSteps].exVowel[1]}
            </strong>
          </TitleTxt>
          <ImageUl className="img_box" selectedWord={selectedWord}>
            {selectedWord ? (
              <ImageLi key={`ex-image-key-${selectedWord}`}>
                <Image
                  selectedWord={selectedWord}
                  src={stages.customData.problem[safeSteps].exImage.filter((e) => e.image.includes(selectedWord))[0].image}
                  alt={stages.customData.problem[safeSteps].exImage.filter((e) => e.image.includes(selectedWord))[0].image}
                />
              </ImageLi>
            ) : (
              _.map(stages.customData.problem[safeSteps].exImage, (item, key) => (
                <ImageLi
                  key={`ex-image-key-${key}`}
                  onClick={() => {
                    handleClickImg(item.image.split('/image_')[1].split('.png')[0]);
                  }}
                >
                  <S.CorrectCircle isCorrect={answer.includes(item.image.split('/image_')[1].split('.png')[0])} />
                  <Image selectedWord={selectedWord} src={item.image} alt={item.image} />
                </ImageLi>
              ))
            )}
          </ImageUl>
        </Wrap>
        {selectedWord && (
          <S.PenKeyboardWrapMain className="bottom_box" style={{ position: 'relative' }}>
            <S.PenKeyboardWrap style={{ padding: '10px 0' }} className="pen_keyboard_wrap">
              {wrongCount === MAX_WRONG_COUNT ? <OutPut imageWordWrite={false}>{selectedWord}</OutPut> : <></>}
              <Keyboard
                getKey={getKey}
                enabledWritePreview={wrongCount !== MAX_WRONG_COUNT}
                setGetKey={setGetKey}
                imageWordWrite={true}
                onWriteEnd={handleWriteEnd}
              />
            </S.PenKeyboardWrap>
          </S.PenKeyboardWrapMain>
        )}
      </S.ColumnFlex>
      <SoundEffectPM url={clickSound} onEnd={handleSoundEnd} />
    </>
  );
};

export default MultiReviewType;
const Wrap = styled(S.BorderColumnBox)`
  padding: 1.1rem 1.2rem;
  align-self: stretch;
  flex: ${(props) => (props.selectedWord ? '1 1' : 'unset')};
  height: ${(props) => !props.selectedWord && '100%'};
`;
const ImageUl = styled.ul`
  display: ${(props) => (props.selectedWord ? 'flex' : 'grid')};
  grid-template: ${(props) => (props.selectedWord ? 'repeat(1, 1fr) / repeat(1, 1fr)' : 'repeat(2, 1fr) / repeat(4, 1fr)')};
  grid-gap: 1rem;
  /* flex: 2 1; */
  justify-content: ${(props) => props.selectedWord && 'center'};
  align-items: ${(props) => props.selectedWord && 'center'};
  /* margin-right: 2rem; */
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
`;
const ImageLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 1rem;
  border: 3px solid #e5e5e5;
`;
const Image = styled.img`
  height: 20vh;
`;
const TitleTxt = styled.h4`
  margin: 0 0 15;
  align-self: center;
  padding: 8px 32px;
  border-radius: 32px;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  background-color: #ffeed8;
`;
const OutPut = styled(S.OutPut)`
  margin-bottom: -4px;
  color: red;
  @media (max-height: 500px) {
    margin-bottom: 0;
  }
`;
