import React from 'react';
import ClassRoomManager from './ClassRoomManager';
import ClassRoomManagerForSmartEclass from './ClassRoomManagerForSmartEclass';
import ClassRoomStudent from './ClassRoomStudent';
import { userInfoVar } from 'src/apollo/cache';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

function ClassRoom() {
  const companyName = useSelector(classStoreData);
  const type = userInfoVar()?.type;
  return type > 0 ? (
    isAfterSchool(companyName) ? (
      <ClassRoomManagerForSmartEclass />
    ) : (
      <ClassRoomManager />
    )
  ) : (
    <ClassRoomStudent isAfterSchool={isAfterSchool(companyName)} companyName={companyName} gotoPhonics={false} />
  );
}

export default ClassRoom;
