import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import PageNum from './PageNum';
import SoundButton from './SoundButton';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { isVowel, getTextInPink, alphabetBoxTypeChecker } from '../../utils/pm/util';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
const defaultImage = 'https://cdn.cloubot.com/PM/image/default_monster_gray.png';
let bookNameUnitStage = undefined;
let bookUnitStagechecklist = undefined;
let bookunitstagechecklistValue = {};
let bookUnitStagechecklistexcep = undefined;
let letterformation4aunit4stage6 = undefined;
let letterformation4aunit4stage6Add = undefined;
let bookNameUnitStageAdd = undefined;
let bookNameUnitStageChecklist = undefined;
let letterformationaunitstageAddExcp = undefined;
let urlbookname = '';
let urlunitname = '';
let urlstagename = '';
const ImgDnD2 = ({ upperWord: problems, stages, isCorrect, hasSoundBtn, answerAbsolute, steps, setSteps, directionEnd }) => {
  const [dropSound, setDropSound] = useState(undefined);
  const [wordSound, setWordSound] = useState(undefined);
  const [soundName, setSoundName] = useState(undefined);

  const [consonantItem, setConsonantItem] = useState(undefined);
  const [vowelItem, setVowelItem] = useState(undefined);
  const [thirdItem, setThirdItem] = useState(undefined);

  const [isFirstDropped, setIsFirstDropped] = useState(false);
  const [isSecondDropped, setIsSecondDropped] = useState(false);
  const [isThirdDropped, setIsThirdDropped] = useState(false);

  const { level, unit, stage } = useParams();

  urlbookname = level;
  urlunitname = unit;
  urlstagename = stage;

  if (level == '2a' && unit == 5 && (stage == 1 || 2 || 5)) {
    bookNameUnitStage = true;
  }

  if (level == '3a' && (unit == 1 || 2) && (stage == 2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (level == '3b' && unit == (6 || 7 || 8) && stage == (2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (level == '4b' && unit == 8 && stage == 6) {
    bookUnitStagechecklist = true;
    bookunitstagechecklistValue = { o: 0, w: 0, a: 0 };
  }

  if (level == '4b' && unit == 9 && stage == 6) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookunitstagechecklistValue = { o: 0, w: 0, u: 0 };
  }

  if (level == '4b' && unit == 10 && stage == 6) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookunitstagechecklistValue = { o: 0 };
  }

  if (level == '4b' && unit == 6 && stage == 6) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookunitstagechecklistValue = { a: 0, i: 0, y: 0 };
  }

  if (level == '4a' && unit == 5 && stage == 6) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookunitstagechecklistValue = { c: 0, g: 0 };
  }

  if (level == '4a' && unit == 4 && stage == 6) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookUnitStagechecklistexcep = true;
    letterformation4aunit4stage6 = {
      thin: [{ loopkey1: 0, loopkey2: 1 }],
      three: [{ loopkey1: 0, loopkey2: 1 }],
      white: [{ loopkey1: 0, loopkey2: 1 }],
      whale: [{ loopkey1: 0, loopkey2: 1 }],
    };
    letterformation4aunit4stage6Add = [{ th: true, wh: true, in: false, ree: false, ale: false }];
    bookunitstagechecklistValue = { t: 0, h: 0, w: 0 };
  }

  if (level == '3b' && unit == 9 && stage == 7) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookUnitStagechecklistexcep = true;
    letterformation4aunit4stage6 = {
      can: [{ loopkey1: -1, loopkey2: -1 }],
      tap: [{ loopkey1: -1, loopkey2: -1 }],
      kite: [{ loopkey1: 3, loopkey2: 3 }],
      pin: [{ loopkey1: -1, loopkey2: -1 }],
      hope: [{ loopkey1: 3, loopkey2: 3 }],
      pop: [{ loopkey1: -1, loopkey2: -1 }],
      pope: [{ loopkey1: 3, loopkey2: 3 }],
      tube: [{ loopkey1: 3, loopkey2: 3 }],
      cub: [{ loopkey1: -1, loopkey2: -1 }],
    };
    letterformationaunitstageAddExcp = [{ e: 'e' }];
    bookNameUnitStageAdd = true;
    bookNameUnitStageChecklist = { e: true };
  }

  if (level == '4a' && unit == 3 && stage == 6) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookUnitStagechecklistexcep = true;
    letterformation4aunit4stage6 = {
      chip: [{ loopkey1: 0, loopkey2: 1 }],
      lunch: [{ loopkey1: 3, loopkey2: 4 }],
      shape: [{ loopkey1: 0, loopkey2: 1 }],
      fish: [{ loopkey1: 2, loopkey2: 3 }],
    };
    letterformation4aunit4stage6Add = [{ ch: true, sh: true, ip: false, nch: false, ape: false, fi: false }];
    bookunitstagechecklistValue = { t: 0, h: 0, w: 0 };
  }

  if (level == '4a' && unit == 2 && stage == 6) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookUnitStagechecklistexcep = true;
    letterformation4aunit4stage6 = {
      bride: [{ loopkey1: 0, loopkey2: 1 }],
      cross: [{ loopkey1: 0, loopkey2: 1 }],
      dress: [{ loopkey1: 0, loopkey2: 1 }],
      green: [{ loopkey1: 0, loopkey2: 1 }],
    };
    letterformation4aunit4stage6Add = [{ br: true, dr: true, cr: true, gr: true, ide: false, oss: false, ess: false, een: false }];
    bookunitstagechecklistValue = { t: 0, h: 0, w: 0 };
  }

  if (level == '4a' && unit == 1 && stage == 6) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookUnitStagechecklistexcep = true;
    letterformation4aunit4stage6 = {
      globe: [{ loopkey1: 0, loopkey2: 1 }],
      black: [{ loopkey1: 0, loopkey2: 1 }],
      flame: [{ loopkey1: 0, loopkey2: 1 }],
      slide: [{ loopkey1: 0, loopkey2: 1 }],
    };
    letterformation4aunit4stage6Add = [{ gl: true, bl: true, fl: true, sl: true, obe: false, ack: false, ame: false, ide: false }];
    bookunitstagechecklistValue = { t: 0, h: 0, w: 0 };
  }

  if (level == '3b' && unit == 9 && stage == 7) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookUnitStagechecklistexcep = true;
    letterformation4aunit4stage6 = {
      can: [{ loopkey1: 0, loopkey2: 0 }],
      tap: [{ loopkey1: 0, loopkey2: 0 }],
      kite: [{ loopkey1: 0, loopkey2: 0 }],
      pin: [{ loopkey1: 0, loopkey2: 0 }],
      hope: [{ loopkey1: 0, loopkey2: 0 }],
      pop: [{ loopkey1: 0, loopkey2: 0 }],
      tube: [{ loopkey1: 0, loopkey2: 0 }],
      cub: [{ loopkey1: 0, loopkey2: 0 }],
    };
    letterformation4aunit4stage6Add = [
      {
        c: true,
        t: true,
        k: true,
        p: true,
        h: true,
        t: true,
        an: false,
        ap: false,
        ite: false,
        in: false,
        ope: false,
        op: false,
        ube: false,
        ub: false,
      },
    ];
    bookunitstagechecklistValue = { t: 0, h: 0, w: 0 };
  }

  if (level == '3a' && unit == 1 && stage == 5) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookUnitStagechecklistexcep = true;
    letterformation4aunit4stage6 = {
      bake: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      case: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      date: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      cake: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      base: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      gate: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      lake: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      vase: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      late: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
    };
    letterformation4aunit4stage6Add = [
      {
        b: false,
        c: false,
        d: false,
        b: false,
        g: false,
        l: false,
        v: false,
        ake: true,
        ase: true,
        ate: true,
        ake: true,
        ase: true,
        ate: true,
        ake: true,
        ase: true,
        ate: true,
      },
    ];
    bookunitstagechecklistValue = { a: 0, k: 0, e: 0, s: 0, e: 0, t: 0 };
  }

  if (level == '3b' && unit == 6 && stage == 7) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookUnitStagechecklistexcep = true;
    letterformation4aunit4stage6 = {
      hole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      dome: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      bone: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      pole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      home: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      cone: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      mole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
    };
    letterformation4aunit4stage6Add = [
      {
        h: false,
        d: false,
        b: false,
        p: false,
        c: false,
        m: false,
        ole: true,
        ome: true,
        one: true,
        ole: true,
        ome: true,
        one: true,
        ole: true,
      },
    ];
    bookunitstagechecklistValue = { t: 0, h: 0, w: 0 };
  }

  if (level == '3b' && unit == 6 && stage == 5) {
    bookNameUnitStage = true;
    bookUnitStagechecklist = true;
    bookUnitStagechecklistexcep = true;
    letterformation4aunit4stage6 = {
      hole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      dome: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      bone: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      pole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      home: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      cone: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      mole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
    };
    letterformation4aunit4stage6Add = [
      {
        h: false,
        d: false,
        b: false,
        p: false,
        c: false,
        m: false,
        ole: true,
        ome: true,
        one: true,
        ole: true,
        ome: true,
        one: true,
        ole: true,
      },
    ];
    bookunitstagechecklistValue = { t: 0, h: 0, w: 0 };
  }

  const handleDragEnd = useCallback(
    (result) => {
      const { destination } = result;
      if (_.isNil(destination)) {
        setDropSound('wrong_sound1');
        return;
      }
      setDropSound('mouse-click');

      switch (destination.droppableId) {
        case problems.question:
          setIsFirstDropped(true);
          const copy = _.cloneDeep(consonantItem);
          for (let i = 0; i < copy.length; i++) {
            if (copy[i].name === problems.question) {
              copy[i].isDropped = true;
              break;
            }
          }
          setConsonantItem(copy);
          break;
        case problems.answer:
          setIsSecondDropped(true);
          const copy2 = _.cloneDeep(vowelItem);
          for (let i = 0; i < copy2.length; i++) {
            if (copy2[i].name === problems.answer) {
              copy2[i].isDropped = true;
              break;
            }
          }
          setVowelItem(copy2);
          break;
        case problems.answer2:
          setIsThirdDropped(true);
          const copy3 = _.cloneDeep(thirdItem);
          for (let i = 0; i < copy3.length; i++) {
            if (copy3[i].name === problems.answer2) {
              copy3[i].isDropped = true;
              break;
            }
          }
          setThirdItem(copy3);
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line
    [problems, vowelItem, consonantItem],
  );
  const handleDropSoundEnd = () => {
    setDropSound(undefined);
  };

  const handleWordSoundEnd = () => {
    setWordSound(undefined);
  };

  const handleSoundNameEnd = () => {
    setSoundName(undefined);
    setSteps(steps + 1);
  };

  useEffect(() => {
    if (answerAbsolute === true) {
      if (isFirstDropped) {
        const sName = !_.isNil(problems.sound)
          ? problems.sound
          : `${problems.question}_${problems.answer}_${problems.question + problems.answer}`;
        setSoundName(sName);
      }
    } else {
      if (_.isNil(problems.answer2)) {
        if (isFirstDropped && isSecondDropped) {
          // const name = _.isNil(stages.customData.ignorePhoneme)
          // 	? `${upperWord.question}_${upperWord.answer}_${upperWord.question + upperWord.answer}`
          // 	: `${upperWord.question + upperWord.answer}`;

          const sName = !_.isNil(problems.sound)
            ? problems.sound
            : `${problems.question}_${problems.answer}_${problems.question + problems.answer}`;
          setSoundName(sName);
        }
      } else {
        if (isFirstDropped && isSecondDropped && isThirdDropped) {
          setSoundName(`${!_.isNil(problems.sound) ? problems.sound : problems.question + problems.answer + problems.answer2}`);
        }
      }
    }
    // eslint-disable-next-line
  }, [isFirstDropped, isSecondDropped, isThirdDropped, answerAbsolute]);

  useEffect(() => {
    setIsFirstDropped(false);
    setIsSecondDropped(false);
    setIsThirdDropped(false);
  }, [steps]);

  useEffect(() => {
    if (!_.isNil(problems)) {
      if (problems.candidate) {
        setConsonantItem(
          _.map(problems.candidate[0], (item) => {
            return { isDropped: false, name: item };
          }),
        );
        setVowelItem(
          _.map(problems.candidate[1], (item) => {
            return { isDropped: false, name: item };
          }),
        );

        if (problems.candidate[2]) {
          setThirdItem(
            _.map(problems.candidate[2], (item) => {
              return { isDropped: false, name: item };
            }),
          );
        } else {
          setThirdItem(undefined);
        }
      }
    }
  }, [problems]);

  useEffect(() => {
    if (directionEnd && hasSoundBtn) {
      let word = problems.question + problems.answer;
      if (word === 'june') {
        //첫글자가 대문자라서...이상하긴 하지만..
        word = 'June';
      }
      setWordSound(word);
    }
  }, [directionEnd, problems.question, problems.answer, hasSoundBtn]);

  if (_.isNil(problems)) return;

  return (
    <>
      <S.RowFlex style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }} className="main_content  col2 lv4_st6_wrap">
        <S.PageWrap mobile={true}>
          <PageNum
            style={{ fontSize: '1.3rem', fontWeight: 'bold' }}
            num={_.isNil(steps) ? 0 : steps + 1}
            total={_.isNil(stages.customData.problem) ? 0 : stages.customData.problem.length}
          />
        </S.PageWrap>
        <LeftBox className="left_box">
          <S.ColumnFlex
            style={{
              alignItems: 'center',
              flex: '1 1',
              justifyContent: 'center',
            }}
          >
            {hasSoundBtn ? (
              <img
                src={(answerAbsolute ? isFirstDropped : isFirstDropped && isSecondDropped) ? problems.url : defaultImage}
                alt="보기 이미지"
                style={{ height: '30vh' }}
              />
            ) : (
              <img src={problems.url} alt="보기 이미지" style={{ height: '30vh' }} />
            )}
            {hasSoundBtn && !soundName ? (
              <SoundButton
                src={problems && `${problems.question + problems.answer === 'june' ? 'June' : problems.question + problems.answer}.mp3`}
              />
            ) : undefined}
          </S.ColumnFlex>
        </LeftBox>
        <S.ImgDnd2BorderBox style={{ flex: '3 1', marginLeft: '24px' }} className="right_box">
          <S.PageWrap mobile={false}>
            <PageNum
              style={{ fontSize: '1.3rem', fontWeight: 'bold' }}
              num={_.isNil(steps) ? 0 : steps + 1}
              total={_.isNil(stages.customData.problem) ? 0 : stages.customData.problem.length}
            />
          </S.PageWrap>
          <DragDropContext onDragEnd={handleDragEnd}>
            <MultiWordDropZone
              problems={problems}
              isFirstDropped={isFirstDropped}
              isSecondDropped={isSecondDropped}
              isThirdDropped={isThirdDropped}
              answerAbsolute={answerAbsolute}
            />
            <Arrow
              // style={{ height: '2.4rem', margin: '1rem 0' }}
              src="/images/pm/i_arrow_top.svg"
              alt="위로 향하는 화살표"
              className="top_arrow"
            />
            <DraggableWordList
              consonantItem={consonantItem}
              vowelItem={vowelItem}
              thirdItem={thirdItem}
              isFirstDropped={isFirstDropped}
              isSecondDropped={isSecondDropped}
              isThirdDropped={isThirdDropped}
              answerAbsolute={answerAbsolute}
            />
          </DragDropContext>
        </S.ImgDnd2BorderBox>
      </S.RowFlex>
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      {soundName && <SoundEffectPM url={`https://cdn.cloubot.com/PM/audio/sounds/${soundName}.mp3`} onEnd={handleSoundNameEnd} />}
      {wordSound && (
        <SoundEffectPM
          url={!_.isNil(wordSound) ? `https://cdn.cloubot.com/PM/audio/sounds/${wordSound}.mp3` : undefined}
          onEnd={handleWordSoundEnd}
        />
      )}
    </>
  );
};

export default ImgDnD2;

const MultiWordDropZone = ({ problems, isFirstDropped, isSecondDropped, isThirdDropped, answerAbsolute }) => {
  const getClassNameException = (fullWord, item, hex = false) => {
    if (['1'].includes(urlstagename.toString()) && ['3a', '3b'].includes(urlbookname) && fullWord.length == 3 && urlunitname == 10) {
      return hex ? '#f368e0' : 'pink';
    }

    return isVowel(item) && hex ? '#f368e0' : 'pink';
  };
  return (
    <S.AlphabetWrap className="alphabet_wrap">
      <S.AlphabetWrapLi>
        <DroppableBox boxType="row1" letters={problems.question} isDropped={isFirstDropped} />
      </S.AlphabetWrapLi>
      <S.AlphabetWrapLi>
        <div style={{ margin: '0 8px', fontWeight: 800, fontSize: '38px' }} className="sign">
          +
        </div>
      </S.AlphabetWrapLi>
      <S.AlphabetWrapLi>
        {answerAbsolute ? (
          <S.AlphabetBox row={1.57} className="alphabet_box row3">
            {problems.answer && bookNameUnitStage
              ? _.map(problems.answer, (item, key) => (
                  <span
                    data="datacheck15"
                    key={`second-drop-item-alphabet-${key}`}
                    style={{ color: '#f368e0' }}
                    className={`font_${isVowel(item) ? 'pink' : 'pink'}`}
                  >
                    {item}
                  </span>
                ))
              : _.map(problems.answer, (item, key) => (
                  <span
                    data="workingsecond"
                    key={`second-drop-item-alphabet-${key}`}
                    style={{ color: getClassNameException(problems.answer, item, true) }}
                    className={`font_${getClassNameException(problems.answer, item)}`}
                  >
                    {item}
                  </span>
                ))}
          </S.AlphabetBox>
        ) : (
          <DroppableBox boxType="row3" letters={problems.answer} isDropped={isSecondDropped} />
        )}
      </S.AlphabetWrapLi>
      {problems && problems.answer2 && (
        <>
          <S.AlphabetWrapLi>
            <div style={{ margin: '0 8px', fontWeight: 800, fontSize: '38px' }} className="sign">
              +
            </div>
          </S.AlphabetWrapLi>

          <S.AlphabetWrapLi>
            <DroppableBox boxType="row1" letters={problems.answer2} isDropped={isThirdDropped} />
          </S.AlphabetWrapLi>
        </>
      )}
      <S.AlphabetWrapLi>
        <div style={{ margin: '0 8px', fontWeight: 800, fontSize: '38px' }} className="sign">
          =
        </div>
      </S.AlphabetWrapLi>
      <S.AlphabetWrapLi>
        {problems &&
          (_.isNil(problems.answer2) ? (
            <AnswerWordBox
              word={problems.question + problems.answer}
              isAnswered={answerAbsolute ? isFirstDropped : isFirstDropped && isSecondDropped}
            />
          ) : (
            <AnswerWordBox
              word={problems.question + problems.answer + problems.answer2}
              isAnswered={isFirstDropped && isSecondDropped && isThirdDropped}
            />
          ))}
      </S.AlphabetWrapLi>
    </S.AlphabetWrap>
  );
};

const DroppableBox = ({ boxType, letters, isDropped }) => {
  const getClassNameException = (
    fullWord,
    key,
    item,
    bookunitstagechecklistValue,
    type = 1,
    letterformation4aunit4stage6Add = [],
    letterformationaunitstageAddExcp = [],
    hex = false,
  ) => {
    let textInPink = getTextInPink(fullWord, urlbookname, urlunitname, urlstagename);
    if (textInPink.length > 0) {
      fullWord = fullWord.replace(/long-|short-/g, '');
      const start = fullWord.indexOf(textInPink);
      const end = start + textInPink.length - 1;
      if (key >= start && key <= end) {
        return hex ? '#f368e0' : 'pink';
      }
      return hex ? '#2e86de' : 'blue';
    }
    if (['5', '7'].includes(urlstagename.toString()) && ['3a', '3b'].includes(urlbookname) && fullWord.length == 1 && urlunitname != 9) {
      return hex ? '#2e86de' : 'blue';
    } else if (
      ['5', '7'].includes(urlstagename.toString()) &&
      ['3a', '3b'].includes(urlbookname) &&
      fullWord.length == 3 &&
      urlunitname != 9
    ) {
      return hex ? '#f368e0' : 'pink';
    }
    if (['7'].includes(urlstagename.toString()) && ['3a', '3b'].includes(urlbookname) && urlunitname == 9) {
      if (item == 'e') {
        return hex ? '#f368e0' : 'pink';
      } else {
        return hex ? '#2e86de' : 'blue';
      }
    }
    if (urlbookname == '4b' && urlunitname == 6 && urlstagename == 6) {
      if (['ai', 'ay'].includes(fullWord)) {
        return hex ? '#f368e0' : 'pink';
      }
      return hex ? '#2e86de' : 'blue';
    }
    if (urlbookname == '4a' && urlunitname == 5 && urlstagename == 6) {
      return bookunitstagechecklistValue && bookunitstagechecklistValue[item] == 0 ? 'pink' : 'blue';
    }
    if (type == 2) {
      return letterformation4aunit4stage6Add && letterformation4aunit4stage6Add[0][letters] == true ? 'pink' : 'blue';
    }
    if (type == 3) {
      return isVowel(item) ? (hex ? '#f368e0' : 'pink') : hex ? '#2e86de' : 'blue';
    }
    if (type == 4) {
      return letterformationaunitstageAddExcp[0]['e'] == item ? (hex ? '#f368e0' : 'pink') : hex ? '#2e86de' : 'blue';
    }
    return bookunitstagechecklistValue && bookunitstagechecklistValue[item] == 0 ? (hex ? '#f368e0' : 'pink') : hex ? '#2e86de' : 'blue';
  };
  return (
    <Droppable droppableId={letters} type={letters}>
      {(provided, snapshot) => (
        <>
          <S.AlphabetBox
            gray={!isDropped}
            row={alphabetBoxTypeChecker(boxType)}
            className={`alphabet_box ${boxType} ${isDropped ? 'green' : 'gray'}`}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined }}
          >
            {isDropped &&
              (letters.indexOf('-') === -1
                ? bookNameUnitStage
                  ? bookUnitStagechecklistexcep
                    ? letterformationaunitstageAddExcp
                      ? _.map(letters, (item, key) => (
                          <span
                            data2="workingthird"
                            data="datacheck1"
                            key={`second-drop-item-alphabet-${key}`}
                            style={{
                              color: getClassNameException(
                                letters,
                                key,
                                item,
                                bookunitstagechecklistValue,
                                2,
                                letterformation4aunit4stage6Add,
                                letterformationaunitstageAddExcp,
                                true,
                              ),
                            }}
                            className={`font_${getClassNameException(
                              letters,
                              key,
                              item,
                              bookunitstagechecklistValue,
                              2,
                              letterformation4aunit4stage6Add,
                              letterformationaunitstageAddExcp,
                            )}`}
                          >
                            {item}
                          </span>
                        ))
                      : _.map(letters, (item, key) => (
                          <span
                            data2="workingfourth"
                            data="datacheck2"
                            key={`second-drop-item-alphabet-${key}`}
                            style={{
                              color: getClassNameException(
                                letters,
                                key,
                                item,
                                bookunitstagechecklistValue,
                                2,
                                letterformation4aunit4stage6Add,
                                [],
                                true,
                              ),
                            }}
                            className={`font_${getClassNameException(
                              letters,
                              key,
                              item,
                              bookunitstagechecklistValue,
                              2,
                              letterformation4aunit4stage6Add,
                            )}`}
                          >
                            {item}
                          </span>
                        ))
                    : _.map(letters, (item, key) => (
                        <span
                          data2="datacheck3"
                          style={{
                            color: getClassNameException(
                              letters,
                              key,
                              item,
                              bookunitstagechecklistValue,
                              undefined,
                              undefined,
                              undefined,
                              true,
                            ),
                          }}
                          className={`font_${getClassNameException(letters, key, item, bookunitstagechecklistValue)}`}
                        >
                          {item}
                        </span>
                      ))
                  : _.map(letters, (item, key) => (
                      <span
                        data="datacheck4"
                        key={`second-drop-item-alphabet-${key}`}
                        style={{
                          color: getClassNameException(letters, key, item, bookunitstagechecklistValue, 3, undefined, undefined, true),
                        }}
                        className={`font_${getClassNameException(letters, key, item, bookunitstagechecklistValue, 3)}`}
                      >
                        {item}
                      </span>
                    ))
                : _.map(letters.split('-')[1], (item, key) => (
                    <span
                      data="datacheck5"
                      key={`second-drop-item-alphabet-${key}`}
                      style={{
                        color: getClassNameException(letters, key, item, bookunitstagechecklistValue, 3, undefined, undefined, true),
                      }}
                      className={`font_${getClassNameException(letters, key, item, bookunitstagechecklistValue, 3)}`}
                    >
                      {item}
                    </span>
                  )))}
          </S.AlphabetBox>
          <div style={{ display: 'none' }}>{provided.placeholder}</div>
        </>
      )}
    </Droppable>
  );
};

const AnswerWordBox = ({ word, isAnswered }) => {
  const word2 = word === 'june' ? 'June' : word;
  const getClassNameException = (
    fullWord,
    key,
    letterformation4aunit4stage6,
    type = 1,
    letterformationaunitstageAddExcp = [],
    hex = false,
  ) => {
    let textInPink = getTextInPink(fullWord, urlbookname, urlunitname, urlstagename);
    if (textInPink.length > 0) {
      fullWord = fullWord.replace(/long-|short-/g, '');
      const start = fullWord.indexOf(textInPink);
      const end = start + textInPink.length - 1;
      if (key >= start && key <= end) {
        return hex ? '#f368e0' : 'pink';
      }
      return hex ? '#2e86de' : 'blue';
    }
    if (urlbookname == '4a' && urlunitname == 5 && urlstagename == 6) {
      return bookunitstagechecklistValue[fullWord[key]] == 0 && bookunitstagechecklistValue[fullWord[key]] !== undefined
        ? hex
          ? '#f368e0'
          : 'pink'
        : hex
        ? '#2e86de'
        : 'blue';
    }
    if (['5', '7'].includes(urlstagename.toString()) && ['3a', '3b'].includes(urlbookname) && fullWord.length == 1 && urlunitname != 9) {
      return hex ? '#2e86de' : 'blue';
    } else if (
      ['5', '7'].includes(urlstagename.toString()) &&
      ['3a', '3b'].includes(urlbookname) &&
      fullWord.length == 3 &&
      urlunitname != 9
    ) {
      return hex ? '#f368e0' : 'pink';
    } else if (
      ['5', '7'].includes(urlstagename.toString()) &&
      ['3a', '3b'].includes(urlbookname) &&
      fullWord.length == 4 &&
      key > 0 &&
      urlunitname != 9
    ) {
      return hex ? '#f368e0' : 'pink';
    } else if (
      ['5', '7'].includes(urlstagename.toString()) &&
      ['3a', '3b'].includes(urlbookname) &&
      fullWord.length == 4 &&
      key == 0 &&
      urlunitname != 9
    ) {
      return hex ? '#2e86de' : 'blue';
    } else if (
      ['1'].includes(urlstagename.toString()) &&
      ['3a', '3b'].includes(urlbookname) &&
      fullWord.length == 4 &&
      key > 0 &&
      urlunitname == 10
    ) {
      return hex ? '#f368e0' : 'pink';
    } else if (
      ['1'].includes(urlstagename.toString()) &&
      ['3a', '3b'].includes(urlbookname) &&
      fullWord.length == 4 &&
      key == 0 &&
      urlunitname == 10
    ) {
      return hex ? '#2e86de' : 'blue';
    }
    if (type == 2) {
      return key == 0 ? (hex ? '#f368e0' : 'pink') : hex ? '#2e86de' : 'blue';
    }
    if (type == 3) {
      return letterformationaunitstageAddExcp[0]['e'] == fullWord[key] ? (hex ? '#f368e0' : 'pink') : hex ? '#2e86de' : 'blue';
    }
    if (type == 4) {
      return bookunitstagechecklistValue[fullWord[key]] == 0 && bookunitstagechecklistValue[fullWord[key]] !== undefined
        ? hex
          ? '#f368e0'
          : 'pink'
        : hex
        ? '#2e86de'
        : 'blue';
    }
    if (type == 5) {
      return key == 0 ? (hex ? '#2e86de' : 'blue') : hex ? '#f368e0' : 'pink';
    }
    return letterformation4aunit4stage6[fullWord] &&
      (letterformation4aunit4stage6[fullWord][0]['loopkey1'] == key ||
        letterformation4aunit4stage6[fullWord][0]['loopkey2'] == key ||
        letterformation4aunit4stage6[fullWord][0]['loopkey3'] == key)
      ? hex
        ? '#f368e0'
        : 'pink'
      : hex
      ? '#2e86de'
      : 'blue';
  };
  return (
    <LongBox
      row={alphabetBoxTypeChecker(3)}
      className={`alphabet_box row3 ${isAnswered ? 'green' : 'gray'}`}
      gray={!isAnswered}
      // style={{ minWidth: '8.8rem', width: '100%' }}
    >
      {word2 &&
        isAnswered &&
        (bookNameUnitStage || bookUnitStagechecklist
          ? bookUnitStagechecklist
            ? letterformation4aunit4stage6
              ? letterformationaunitstageAddExcp
                ? _.map(word2.replace(/long-|short-/g, ''), (item, key) => (
                    <span
                      data="datacheck6"
                      key={`answer-word-alphabet-item-${key}`}
                      className={`font_${getClassNameException(
                        word2,
                        key,
                        letterformation4aunit4stage6,
                        3,
                        letterformationaunitstageAddExcp,
                      )}`}
                    >
                      {item}
                    </span>
                  ))
                : _.map(word2.replace(/long-|short-/g, ''), (item, key) => (
                    <span
                      data="datacheck7"
                      key={`answer-word-alphabet-item-${key}`}
                      className={`font_${getClassNameException(word2, key, letterformation4aunit4stage6)}`}
                    >
                      {item}
                    </span>
                  ))
              : _.map(word2.replace(/long-|short-/g, ''), (item, key) => (
                  <span
                    data="datacheck8"
                    key={`answer-word-alphabet-item-${key}`}
                    className={`font_${getClassNameException(word2, key, letterformation4aunit4stage6, 4)}`}
                  >
                    {item}
                  </span>
                ))
            : _.map(word2.replace(/long-|short-/g, ''), (item, key) => (
                <span
                  data="datacheck9"
                  key={`answer-word-alphabet-item-${key}`}
                  className={`font_${getClassNameException(word2, key, letterformation4aunit4stage6, 5)}`}
                >
                  {item}
                </span>
              ))
          : _.map(word2.replace(/long-|short-/g, ''), (item, key) => (
              <span
                data="datacheck10"
                key={`answer-word-alphabet-item-${key}`}
                className={`font_${getClassNameException(word2, key, letterformation4aunit4stage6, 2)}`}
              >
                {item}
              </span>
            )))}
    </LongBox>
  );
};

const DraggableWordList = ({ consonantItem, vowelItem, thirdItem, isFirstDropped, isSecondDropped, isThirdDropped }) => {
  return (
    <S.AlphabetBottomWrap className="gray_bg_border_top">
      <DraggableWordsWrap boxType="row1" wordItem={consonantItem} isDropped={isFirstDropped} />
      {vowelItem && vowelItem.length > 0 && <DraggableWordsWrap boxType="row3" wordItem={vowelItem} isDropped={isSecondDropped} />}
      {thirdItem && <DraggableWordsWrap boxType="row1" wordItem={thirdItem} isDropped={isThirdDropped} />}
    </S.AlphabetBottomWrap>
  );
};

const DraggableWordsWrap = ({ boxType, wordItem, isDropped }) => {
  if (wordItem !== undefined) {
    let bookbunitstagechecklist = { oa: 0, ow: 0, ou: 0, ai: 0, ay: 0, c: 0, g: 0 };

    if (urlbookname == '4b' && urlunitname == 10 && urlstagename == 6) {
      bookbunitstagechecklist = { oa: 0, ow: 0, ou: 0, ai: 0, ay: 0 };
    }

    let completeword = [];
    var worddataresult = [];
    var book4_loop = undefined;
    wordItem.map((worddata, key) => {
      let popstring = worddata.name.split('-').pop();
      if (worddata.name.indexOf('-') != -1) {
        book4_loop = true;
        let innerloop = worddata.name.split('-')[1].split('');
        let keycount = worddata.name.split('-')[0].split('').length + 1;
        innerloop.map((innerdata) => {
          worddataresult[worddata.name + keycount] = true;
          keycount++;
        });
      }

      if (urlbookname == '4b' && (urlunitname == 8 || 9) && urlstagename == 6) {
        bookbunitstagechecklist = { oa: 0, ow: 0 };
        if ((bookbunitstagechecklist[worddata.name] == 0 && worddata.name == 'ou') || 'ow' || 'oa') {
          book4_loop = true;
          worddataresult[worddata.name + bookbunitstagechecklist[worddata.name]] = true;
          worddataresult[worddata.name + (bookbunitstagechecklist[worddata.name] + 1)] = true;
        }
      }

      if (urlbookname == '4b' && urlunitname == 6 && urlstagename == 6) {
        bookbunitstagechecklist = { ai: 0, ay: 0 };
        if ((bookbunitstagechecklist[worddata.name] == 0 && worddata.name == 'ai') || 'ay') {
          book4_loop = true;
          worddataresult[worddata.name + bookbunitstagechecklist[worddata.name]] = true;
          worddataresult[worddata.name + (bookbunitstagechecklist[worddata.name] + 1)] = true;
        }
      }

      if (urlbookname == '4a' && urlunitname == 4 && urlstagename == 6) {
        bookbunitstagechecklist = { th: 0, wh: 0 };
        if ((bookbunitstagechecklist[worddata.name] == 0 && worddata.name == 'th') || 'wh') {
          book4_loop = true;
          worddataresult[worddata.name + bookbunitstagechecklist[worddata.name]] = true;
          worddataresult[worddata.name + (bookbunitstagechecklist[worddata.name] + 1)] = true;
        }
      }

      if (urlbookname == '4a' && urlunitname == 3 && urlstagename == 6) {
        bookbunitstagechecklist = { ch: 0, sh: 0 };
        if ((bookbunitstagechecklist[worddata.name] == 0 && worddata.name == 'ch') || 'sh') {
          book4_loop = true;
          worddataresult[worddata.name + bookbunitstagechecklist[worddata.name]] = true;
          worddataresult[worddata.name + (bookbunitstagechecklist[worddata.name] + 1)] = true;
        }
      }

      if (urlbookname == '4a' && urlunitname == 2 && urlstagename == 6) {
        bookbunitstagechecklist = { br: 0, cr: 0, dr: 0, gr: 0 };
        if ((bookbunitstagechecklist[worddata.name] == 0 && worddata.name == 'br') || 'cr' || 'dr' || 'gr') {
          book4_loop = true;
          worddataresult[worddata.name + bookbunitstagechecklist[worddata.name]] = true;
          worddataresult[worddata.name + (bookbunitstagechecklist[worddata.name] + 1)] = true;
        }
      }

      if (urlbookname == '4a' && urlunitname == 1 && urlstagename == 6) {
        bookbunitstagechecklist = { bl: 0, fl: 0, gl: 0, sl: 0 };
        if ((bookbunitstagechecklist[worddata.name] == 0 && worddata.name == 'bl') || 'fl' || 'gl' || 'sl') {
          book4_loop = true;
          worddataresult[worddata.name + bookbunitstagechecklist[worddata.name]] = true;
          worddataresult[worddata.name + (bookbunitstagechecklist[worddata.name] + 1)] = true;
        }
      }

      if (urlbookname == '4a' && urlunitname == 5 && urlstagename == 6) {
        if (bookbunitstagechecklist[worddata.name] == 0) {
          book4_loop = true;
          worddataresult[worddata.name + bookbunitstagechecklist[worddata.name]] = true;
          worddataresult[worddata.name + (bookbunitstagechecklist[worddata.name] + 1)] = true;
        } else {
          book4_loop = true;
          worddataresult[worddata.name + bookbunitstagechecklist[worddata.name]] = false;
          worddataresult[worddata.name + (bookbunitstagechecklist[worddata.name] + 1)] = false;
        }
      }
    });
  }
  const getClassNameException = (key2, fullWord, type = 1, bookNameUnitStageChecklist = [], worddataresult = {}, hex = false) => {
    let textInPink = getTextInPink(fullWord, urlbookname, urlunitname, urlstagename);
    if (textInPink.length > 0) {
      const start = fullWord.indexOf(textInPink);
      const end = start + textInPink.length - 1;
      if (key2 >= start && key2 <= end) {
        return hex ? '#f368e0' : 'pink';
      }
      return hex ? '#2e86de' : 'blue';
    }
    if (['5', '7'].includes(urlstagename.toString()) && ['3a', '3b'].includes(urlbookname) && fullWord.length == 1 && urlunitname != 9) {
      return hex ? '#2e86de' : 'blue';
    } else if (
      ['5', '7'].includes(urlstagename.toString()) &&
      ['3a', '3b'].includes(urlbookname) &&
      fullWord.length == 3 &&
      urlunitname != 9
    ) {
      return hex ? '#f368e0' : 'pink';
    } else if (
      ['1'].includes(urlstagename.toString()) &&
      ['3a', '3b'].includes(urlbookname) &&
      fullWord.length == 1 &&
      ['5', '10'].includes(urlunitname.toString())
    ) {
      return hex ? '#2e86de' : 'blue';
    }
    if (type == 2) {
      return isVowel(fullWord[key2]) ? (hex ? '#f368e0' : 'pink') : hex ? '#2e86de' : 'blue';
    }
    if (type == 3) {
      return bookNameUnitStageChecklist[fullWord[key2]] == true ? (hex ? '#f368e0' : 'pink') : 'blue';
    }
    if (type == 4) {
      return worddataresult[fullWord + key2] ? (hex ? '#f368e0' : 'pink') : hex ? '#2e86de' : 'blue';
    }
    return key2 ? (hex ? '#f368e0' : 'pink') : hex ? '#f368e0' : 'pink';
  };
  return (
    <S.AlphabetBottomWrapUl className={`alphabet_wrap ${boxType}_wrap`}>
      {wordItem &&
        _.map(wordItem, (item, index) => (
          <Droppable key={`droppable-wrapper-item-${item.name}`} droppableId={'drag' + item.name} type={item.name} isDropDisabled={true}>
            {(provided) => (
              <li style={{ padding: '10px 6px' }} {...provided.droppableProps} ref={provided.innerRef}>
                <Draggable draggableId={item.name} index={index} isDragDisabled={isDropped}>
                  {(provided) => (
                    <S.AlphabetBox
                      row={alphabetBoxTypeChecker(item.name.length > 1 ? item.name.length : boxType)}
                      gray={false}
                      className={`alphabet_box ${item.name.length > 1 ? 'row2' : boxType} green ${
                        item.name.length > 6 ? 'width-auto' : ''
                      } ${item.isDropped ? 'area_hide' : ''}`}
                      hide={item.isDropped}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {book4_loop !== undefined
                        ? _.map(item.name, (alphabet, key2) => (
                            <span
                              data="datacheck11"
                              style={{ color: getClassNameException(key2, item.name, 4, bookNameUnitStageChecklist, worddataresult, true) }}
                              className={`font_${getClassNameException(key2, item.name, 4, bookNameUnitStageChecklist, worddataresult)}`}
                            >
                              {alphabet}
                            </span>
                          ))
                        : bookNameUnitStage
                        ? bookNameUnitStageAdd
                          ? _.map(item.name, (alphabet, key2) => (
                              <span
                                data="datacheck12"
                                style={{ color: getClassNameException(key2, item.name, 3, bookNameUnitStageChecklist, undefined, true) }}
                                className={`font_${getClassNameException(key2, item.name, 3, bookNameUnitStageChecklist)}`}
                              >
                                {alphabet}
                              </span>
                            ))
                          : _.map(item.name, (alphabet, key2) => (
                              // <span data="datacheck13" className={`font_${getClassNameException(key2, item.name)}`}>{alphabet}</span>
                              <span style={{ color: '#2e86de' }} data="datacheck13" className="font_blue">
                                {alphabet}
                              </span>
                            ))
                        : _.map(item.name, (alphabet, key2) => (
                            <span
                              data="datacheck14"
                              key={`question-vowel-item${key2}`}
                              style={{ color: getClassNameException(key2, item.name, 2, undefined, undefined, true) }}
                              className={`font_${getClassNameException(key2, item.name, 2)}`}
                            >
                              {alphabet}
                            </span>
                          ))}
                    </S.AlphabetBox>
                  )}
                </Draggable>
                {provided.placeholder}
              </li>
            )}
          </Droppable>
        ))}
    </S.AlphabetBottomWrapUl>
  );
};
const Arrow = styled.img`
  height: 2.4rem;
  margin: 1rem 0;
  @media (max-height: 500px) {
    height: 1.5rem;
    margin: 0.1rem 0;
  }
`;
const LeftBox = styled(S.ImgDnd2BorderBox)`
  flex: 2 1;
  @media (max-height: 500px) {
    flex: unset;
  }
`;
const LongBox = styled(S.AlphabetBox)`
  min-width: 8.8rem;
  width: 100%;
  @media (max-height: 500px) {
    min-width: 6rem;
  }
`;
