import React, { useState, useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { Table, Typography, Modal } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import { useHistory } from 'react-router-dom';
import { classStoreData } from 'src/operations/store';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { settings } from 'src/dummy/settings.js';
import NewsOMaticStudyButton from 'src/components/common/NewsOMaticStudyButton';
import BookrOpenModal from 'src/components/Modal/BookrOpenModal';
import {
  GET_DAILY_TEST_SCHEDULE,
  GET_DAILY_BOOK_SCHEDULE,
  GET_DAILY_STUDY_SCHEDULE,
  GET_DAILY_ZOOM_SCHEDULE,
  GET_DAILY_PHONICS_SCHEDULE,
  GET_DAILY_OFFLINE_SCHEDULE,
  GET_DAILY_OFFLINE_HOMEWORK_SCHEDULE,
  GET_DAILY_EXTERNAL_STUDY_SCHEDULE,
  GET_DAILY_EXTERNAL_STUDY_SCHEDULE_FOR_STUDENT,
  GET_DAILY_AI_TOPIC_STUDENT_SCHEDULE,
  GET_DAILY_TEST_GROUP_SCHEDULE,
} from 'src/operations/queries/getClass';
import { rootStateVar } from 'src/apollo/cache';
import { isPturn } from 'src/utils';
import OpenAiModal from 'src/components/Modal/OpenAiModal';
import ViewAnswerModal from 'src/components/Modal/ViewAnswerModal';
import PhonicsNoticePopupForStudents from 'src/popups/PhonicsNoticePopupForStudents';
import StudyNoticePopupForStudents from 'src/popups/StudyNoticePopupForStudents';
//const today = new Date(); //component안에 들어가니까 무한 랜더링이 된다.. 쩝.
const { Title } = Typography;
const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;

const TodoCategory = styled.div`
  text-align: center;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  padding: 0;
`;

const StudyComplete = styled(TodoCategory)`
  background-color: #4ced49;
`;

const StudyNoComplete = styled(TodoCategory)`
  /* background-color: #ea4444; */
  background-color: #ef811a;
`;

const StudIng = styled(TodoCategory)`
  background-color: #5d9cec;
`;

const StudOnline = styled(TodoCategory)`
  background-color: #a0d468;
`;

const StudyBefore = styled(TodoCategory)`
  background-color: #ffce54;
`;

//가독성을 위해서 위에 것하고 겹치지만 evine용으로 한 세트 더 만듦
const StudyCompleteGray = styled(TodoCategory)`
  background-color: #dee2e6;
`;
const StudyVoca = styled(TodoCategory)`
  background-color: #ffce54;
`;
const StudySentence = styled(TodoCategory)`
  background-color: #5d9cec;
`;
const StudyEssay = styled(TodoCategory)`
  background-color: #a0d468;
`;

const StudyRecording = styled(TodoCategory)`
  background-color: #ea4444;
`;

const AssignedTest = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #ff7f00;
`;
const DailyTestGroupAssigned = styled(TodoCategory)`
  background-color: #ff7f00;
`;
const AssignedBooks = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #8000ff;
`;

const ZoomSchedule = styled(TodoCategory)`
  /* background-color: #3c79f6; */
  background-color: #f2ac52;
`;

const PhonicsSchedule = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #ffbf32;
`;

const OfflineSchedule = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #4e9b4e;
`;
const AssignedEvineBook = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #6eb2c3;
`;

const STAGE_GROUP_NAMES_EVINE = {
  voca: () => <StudyVoca>VOCA</StudyVoca>,
  sentence: () => <StudySentence>Key Sentence</StudySentence>,
  essay: () => <StudyEssay>Essay</StudyEssay>,
  AssignedTests: () => <AssignedTest className="test-schedule-list">Test</AssignedTest>,
  DailyTestGroupAssigned: () => <DailyTestGroupAssigned className="test-schedule-list">Daily Test</DailyTestGroupAssigned>,
  ZoomSchedule: () => <ZoomSchedule>Zoom Meeting</ZoomSchedule>,
  recording: () => <StudyRecording className="elibrary-schedule-list">Recording</StudyRecording>,
  //AssignedBooks: () => <AssignedBooks>Library</AssignedBooks>,
  AssignedBooks: (a, cmpName) => (
    <AssignedBooks>
      {cmpName === 'lucid' ? <div style={{ backgroundColor: '#FF9900', borderRadius: '50px' }}>Library</div> : <div>Library</div>}
    </AssignedBooks>
  ),
  Phonics: () => <PhonicsSchedule className="phonics-schedule-list">Phonics</PhonicsSchedule>,
  Offline: () => <OfflineSchedule className="offline-schedule-list">Offline</OfflineSchedule>,
};

const TodayTable = styled(Table)`
  width: '50%';
  height: '34vh';
  cursor: pointer;
`;

const studyState = {
  1: (text) => {
    return <StudyComplete className="study-complete">{text}</StudyComplete>;
  },
  2: (text) => {
    return <StudyBefore className="study-before">{text}</StudyBefore>;
  },
  3: (text) => {
    return <StudyBefore className="study-before">{text}</StudyBefore>;
  },
  4: (text) => {
    return <StudIng className="studing-list">{text}</StudIng>;
  },
  5: (text) => {
    return <StudyCompleteGray className="study-complete-gray">{text}</StudyCompleteGray>;
  },
  6: (text) => {
    return <ZoomSchedule className="zoom-Schedule">{text}</ZoomSchedule>;
  },
  7: (text, backgroundColor) => {
    return <TodoCategory style={{ backgroundColor }}>{text}</TodoCategory>;
  },
  8: (text) => {
    return <OfflineSchedule className="offline-schedule-list">{text}</OfflineSchedule>;
  },
  9: (text) => {
    return <AssignedEvineBook>{text}</AssignedEvineBook>;
  },
  10: (text, backgroundColor) => {
    return <TodoCategory style={{ backgroundColor }}>{text}</TodoCategory>;
  },
  default: (text) => <StudOnline className="study-online">{text}</StudOnline>,
};

const getStatusText = (company, status) => {
  switch (company) {
    case 'creo':
      return status === 1 ? 'Completed' : 'Not Completed';
    default:
      return status === 1 ? '학습완료' : '미완료';
  }
};

// function TodayStudy({ Fake_data }) {
function TodayStudy({ Fake_data }) {
  const defaultClass = useSelector(classStoreData);
  const history = useHistory();
  const location = useLocation();
  const token = getCurrentToken();
  const [user, setUser] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [isReadOnly, setIsReadOnly] = useState(false);
  const {
    calendar: { selectDay },
  } = useReactiveVar(rootStateVar);
  const companySettings = settings[defaultClass];

  const { data } = useQueryProxy(USER_ME, { variables: { token } });

  const [visible, setVisible] = useState(false);
  const [phonicsVisible, setPhonicsVisible] = useState(false);
  const [studyVisible, setStudyVisible] = useState(false);
  const [OfflineText, setOfflineText] = useState(EditorState.createEmpty());
  const [newsOMaticData, setNewsOMaticData] = useState(null);
  const [newsOMaticDataAssign, setNewsOMaticDataAssign] = useState(null);
  const [bookrData, setBookrData] = useState(null);
  const culp_token = window.localStorage.getItem('culp_token');
  const [openAiModal, setOpenAiModal] = useState(false);
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topic, setTopic] = useState();
  const [userTopicId, setUserTopicId] = useState();
  const [userTopicStatus, setUserTopicStatus] = useState();
  const [topicAnswer, setTopicAnswer] = useState('');

  const columns = useMemo(() => {
    return [
      {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
        width: '20%',
        render: (text, it) => {
          if (text === 'Level-Up') {
            return studyState.default(text);
          }
          if (text === 'WORDS') {
            return studyState[3](text);
          }
          if (text === 'Key Sentence') {
            return studyState[4](text);
          }
          if (text === 'Essay') {
            return studyState.default(text);
          }

          const groupCode = it?.studyItem?.book_lesson?.stage_group_code || it?.group_code;
          if (groupCode) {
            const done = it?.isComplete;

            if (done) {
              return studyState[10]('Homework', companySettings.appColors['done']);
            }

            if (Object.keys(companySettings.appColors).includes(`homework_${groupCode}`)) {
              return studyState[10]('Homework', companySettings.appColors[`homework_${groupCode}`]);
            }
            if (Object.keys(STAGE_GROUP_NAMES_EVINE).includes(groupCode)) {
              return STAGE_GROUP_NAMES_EVINE[groupCode](it.studyItem?.stat === '1', defaultClass);
              //return STAGE_GROUP_NAMES_EVINE[groupCode](it.studyItem?.stat === '1');
            }

            if (['reading', 'listening', 'grammar'].includes(groupCode)) {
              return studyState[7](text, '#FF0000');
            }
          }

          //return studyState[4](text);
          if (it.status === 5) {
            return studyState[6](text);
          } else {
            if (it?.type === 'Phonics') {
              return studyState[7]('Phonics');
            } else if (it?.type === 'Offline') {
              return studyState[8]('Offline');
            } else if (it?.type === 'Library') {
              return studyState[10]('Library', companySettings.appColors.book);
            } else if (it?.type === 'newsomatic') {
              return studyState[10](it?.title_type_str, companySettings.appColors.externalStudy);
            } else if (it?.type === 'newsomatic-assign') {
              return studyState[10]('News_O_Matic', companySettings.appColors.News_O_Matic);
            } else if (it?.type === 'bookr') {
              return studyState[10]('Bookr', companySettings.appColors.bookr);
            } else if (it?.type === 'Test') {
              return studyState[10]('Test', companySettings.appColors.test);
            } else if (it?.type === 'ai-topic') {
              return studyState[10]('Ai Topic', companySettings.appColors.AI_Topics);
            } else if (it?.type === 'book-report') {
              return studyState[10]('Writing Coach', companySettings.appColors.Book_Report);
            } else {
              return studyState[4](text);
            }
          }
          //return (studyState[it.status] || studyState.default)(text);
        },
      },
      {
        key: 'title',
        title: 'title',
        dataIndex: 'title',
        style: 'text-align: left',
      },
      {
        key: 'percent',
        title: 'percent',
        dataIndex: 'percent',
        width: '20%',
        style: 'text-align: right',
        render: (text, it) => {
          let Status = null;
          if (it.type === 'test') {
            Status =
              it.status === 2 ? (
                <StudyComplete className="btn-blue icon-smile">{getStatusText(defaultClass, 1)}</StudyComplete>
              ) : (
                <StudyNoComplete className="btn-blue icon-sad">{getStatusText(defaultClass, 0)}</StudyNoComplete>
              );
          } else if (it.type === 'ai-topic' || it.type === 'book-report') {
            Status =
              it.status >= 1 ? (
                <StudyComplete className="btn-blue icon-smile">{getStatusText(defaultClass, 1)}</StudyComplete>
              ) : (
                <StudyNoComplete className="btn-blue icon-sad">{getStatusText(defaultClass, 0)}</StudyNoComplete>
              );
          } else if (it.type === 'test_group') {
            Status =
              it.status === 1 ? (
                <StudyComplete className="btn-blue icon-smile" style={{ cursor: 'not-allowed' }}>
                  {getStatusText(defaultClass, 1)}
                </StudyComplete>
              ) : (
                <StudyNoComplete className="btn-blue icon-sad">{getStatusText(defaultClass, 0)}</StudyNoComplete>
              );
          } else if (it.type === 'ai-talking-tutor') {
            Status =
              it.status === 1 ? (
                <StudyComplete className="btn-blue icon-smile">{getStatusText(defaultClass, 1)}</StudyComplete>
              ) : (
                <StudyNoComplete className="btn-blue icon-sad">{getStatusText(defaultClass, 0)}</StudyNoComplete>
              );
          } else {
            Status = text ? (
              <StudyComplete className="btn-blue icon-smile">{getStatusText(defaultClass, 1)}</StudyComplete>
            ) : it.type == 'offline' ? (
              <span></span>
            ) : (
              <StudyNoComplete className="btn-blue icon-sad">{getStatusText(defaultClass, 0)}</StudyNoComplete>
            );
          }

          if (text === 'zoom') return;
          return <span>{Status}</span>;
        },
      },
    ];
  }, [defaultClass]);

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data]);

  useEffect(() => {
    if (selectDay) {
      setSelectedDate(moment(selectDay).format('yyyy-MM-DD'));
    }
  }, [selectDay]);

  const { data: dailyStudyScheduleList } = useQuery(GET_DAILY_STUDY_SCHEDULE, {
    fetchPolicy: 'no-cache',
    skip: !data,
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });

  const { data: dailyTestScheduleList } = useQuery(GET_DAILY_TEST_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });

  const { data: dailyAiTopicStudentScheduleList, refetch: refetchAiTopics } = useQuery(GET_DAILY_AI_TOPIC_STUDENT_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });

  const { data: dailyBookScheduleList } = useQuery(GET_DAILY_BOOK_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });

  const { data: dailyExternalStudyScheduleList } = useQuery(GET_DAILY_EXTERNAL_STUDY_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });
  const { data: dailyExternalStudyScheduleForStudent } = useQuery(GET_DAILY_EXTERNAL_STUDY_SCHEDULE_FOR_STUDENT, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });

  const { data: dailyPhonicsScheduleList } = useQuery(GET_DAILY_PHONICS_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });

  const { data: dailyOfflineScheduleList } = useQuery(GET_DAILY_OFFLINE_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });

  const { data: dailyTestGrpupScheduleList } = useQuery(GET_DAILY_TEST_GROUP_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      // user_idx: 1000024,
      date_string: selectedDate,
    },
  });

  const [getStudentHomeWorkSchedule] = useLazyQuery(GET_DAILY_OFFLINE_HOMEWORK_SCHEDULE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data !== undefined) {
        let mergeHomeworkcontent = '';
        let resultset = data.AssignedOfflineDailyHomeWorkSchedule;
        if (resultset.length !== 0) {
          data.AssignedOfflineDailyHomeWorkSchedule.map((loopitem, loopkey) => {
            mergeHomeworkcontent += `<strong>Homework : </strong><br/><br/>`;
            mergeHomeworkcontent += loopitem.homeworkcontent == 'homeworkcontent' ? '' : loopitem.homeworkcontent;
            mergeHomeworkcontent += `<br/><br/><strong>Extra Homework : </strong><br/>`;
            mergeHomeworkcontent += loopitem.extra_homework;
            mergeHomeworkcontent += `<br/><br/>`;
            /*if(loopitem.extra_homework == ''){
                mergeHomeworkcontent += `<strong>LessonPlan Homework : </strong><br/>`;
                mergeHomeworkcontent += loopitem.homeworkcontent == 'homeworkcontent' ? '' : loopitem.homeworkcontent;
              }else if(loopitem.homeworkcontent == 'homeworkcontent'){
                let mergeHomeworkcontent = `<strong>Extra Homework : </strong><br/>`;
                mergeHomeworkcontent += loopitem.extra_homework;
              }else{
                let mergeHomeworkcontent = `<strong>LessonPlan Homework : </strong><br/>`;
                mergeHomeworkcontent += loopitem.homeworkcontent;
                mergeHomeworkcontent += `<br/><br/><strong>Extra Homework : </strong>`;
                mergeHomeworkcontent += loopitem.extra_homework;
              }
              console.log(loopitem)*/
          });
          editor_process(mergeHomeworkcontent);
        } else {
          setOfflineText(EditorState.createEmpty());
        }

        function editor_process(html_text_input) {
          const { contentBlocks, entityMap } = htmlToDraft(html_text_input);
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setOfflineText(EditorState.createWithContent(contentState));
        }
      }
    },
  });

  const { data: dailyZoomScheduleList } = useQuery(GET_DAILY_ZOOM_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });

  const onHandleRowClick = (row, index, user) => {
    //if (row.status === 4) {
    var re = new RegExp('^(http|https)://', 'i');
    if (re.test(row.link_url)) {
      // if (window !== window.parent) {
      //   console.log('iframe');
      //   window.location.href = row.link_url;
      //   // window.open(row.link_url);
      // } else {
      // }

      if (window.ReactNativeWebView && row.type === 'study') {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'study',
            lesson_code: row.studyItem.lesson_code,
            user_lesson_idx: row.studyItem.idx,
            token: JSON.parse(culp_token),
            company_name: defaultClass,
          }),
        );
      } else {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'gotoUrl',
              url: row.link_url,
            }),
          );
        } else {
          if (row.group_code === 'Phonics') {
            setPhonicsVisible(true);
          }
          console.log(defaultClass);
          if (defaultClass === 'creo' || 'intoreading') {
            setStudyVisible(true);
          }
          window.open(row.link_url, 'new');
        }
      }
    } else if (row.link_url) {
      if (row.type === 'test' && row.status === 1) {
        const startT = moment(new Date(`${row?.studyItem?.start_time.replace('Z', '')}+09:00`));
        if (moment().isAfter(startT)) {
          history.push(row.link_url);
        } else {
          info('no');
        }
      } else if (row.type === 'test' && row.status === 2) {
        if (row?.studyItem?.idx) {
          history.push(`/test/new/${row?.studyItem?.idx}/default`);
        } else {
          info('end');
        }
      } else if (row.type === 'study') {
        if (row.study_complete) {
          Modal.confirm({
            title: '완료된 학습입니다. 다시 학습하시겠습니까?',
            onOk: () => {
              history.push(row.link_url);
            },
          });
        } else {
          history.push(row.link_url);
        }
      } else if (row.type === 'test_group') {
        if (row?.group_idx && row.status !== 1) {
          history.push(`${row?.link_url}`);
        } else {
          info('end');
        }
      }
    } else if (row.type === 'offline') {
      getStudentHomeWorkSchedule({
        variables: {
          std_bookidx: row.studyItem[0],
          user_idx: row.studyItem[2],
          date_string: row.studyItem[1],
          std_syllabus_idx: row.studyItem[3],
        },
      });

      /*doQuery({
            variables: {
              std_bookidx : row.studyItem[0],
              user_idx : row.studyItem[2],
              date_string : row.studyItem[1],
              std_syllabus_idx : row.studyItem[3]
            },
          })*/

      /*setTimeout(() => {
            console.log('*****************************')
            console.log(StudentHomeworkdata);
            console.log('*****************************')
          },3000);*/
      setVisible(true);
    } else if (row.type === 'newsomatic') {
      setNewsOMaticData({
        article_id: row.article_id,
        assigned_idx: row.key,
      });
    } else if (row.type === 'newsomatic-assign') {
      const editionData = JSON.parse(row?.studyItem?.external_study_data);
      console.log('editionData', editionData);
      setNewsOMaticDataAssign({
        editionId: editionData?.editionId,
      });
    } else if (row.type === 'bookr') {
      let pathArr = location.pathname.split('/');
      pathArr = pathArr.filter((item) => item !== '');
      if (pathArr.length < 1) pathArr = ['dashboard'];
      let returnUrl = `${window.location.origin}/bookr/redirect/${user.idx}/${row.article_id}/${row.key}/${pathArr.join('|')}`;
      if (window.ReactNativeWebView) {
        returnUrl = `culpmobile://oauth/bookr/redirect/${user.idx}/${row.article_id}/${row.key}/${pathArr.join('|')}`;
      }
      setBookrData({
        url: `bookrclass://book/${row.article_id}?ssoId=cloubot&clientToken=${token}&returnUrl=${returnUrl}`,
      });
      // window.location.href = `https://api.v2.bookrclass.com/api/oauth/sso/book/${row.article_id}/login/cloubot/?token=${token}&returnUrl=${returnUrl}`;
      // if (isMobile)
      //   window.location.href = `https://api.v2.bookrclass.com/api/oauth/sso/book/${row.article_id}/login/cloubot/?token=${token}&returnUrl=${returnUrl}`;
      // else
      //   window.open(
      //     `https://api.v2.bookrclass.com/api/oauth/sso/book/${row.article_id}/login/cloubot/?token=${token}&returnUrl=${returnUrl}`,
      //     '_blank',
      //     'noopener,noreferrer',
      //   );
    } else if (row.type === 'levelup') {
      if (row?.studyItem?.idx && !row.status) {
        const external = JSON.parse(row.studyItem.external_study_data);

        // console.log(external,'external')
        const url = external.is_interactive
          ? `/level-up/epub-reader/${external.code}`
          : external.is_voca
          ? `level-up/study/${external.code}`
          : `level-up/reader/${external.code}`;

        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'gotoLevelup',
              assigned_idx: row?.studyItem?.idx,
              bookData: {
                ...external,
                assignedIdx: row?.studyItem?.idx,
              },
            }),
          );
        } else {
          history.push(url, {
            assigned_idx: row?.studyItem?.idx,
            bookData: {
              ...external,
              assignedIdx: row?.studyItem?.idx,
            },
          });
        }
      } else {
        history.push('level-up/results/assignresult');
      }
    } else if (row.type === 'book-report') {
      if (row?.studyItem?.idx && row.status !== 2) {
        history.push(`/aicoach/writing/${row?.studyItem?.idx}`);
      } else {
        history.push('/aicoach/result');
      }
    } else if (row.type === 'ai-talking-tutor') {
      if (row?.studyItem?.idx && row.status !== 1) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'ai-talking-tutor',
              lesson_code: row.studyItem.lesson_code,
              user_lesson_idx: row.studyItem.idx,
              token: JSON.parse(culp_token),
              company_name: defaultClass,
              company_idx: data?.me?.campus?.fc_company_idx,
              user: data?.me?.id,
              chat_user_name: data?.me?.name,
              idx: row?.studyItem?.idx,
            }),
          );
        } else {
          history.push(`/ai-talking-tutor/writing/${row?.studyItem?.idx}`);
        }
      } else {
        history.push('/ai-talking-tutor/result');
      }
    } else if (row.type === 'ai-topic') {
      if (row.status === 2) {
        setTopic(row.title);
        setUserTopicId(row.studyItem.idx);
        setTopicAnswer(row.studyItem.answer);
        setViewAnswerModal(true);
      } else {
        setTopic(row.title);
        setUserTopicId(row.studyItem.idx);
        setUserTopicStatus(row.status);
        setOpenAiModal(true);
      }
    }
    //새창이 아니고, 걍 주소 이동임

    //}

    if (window.Android && row.isMeeting) {
      if (user.type === 0) {
        window.Android.joinZoomClass(JSON.stringify(user));
      } else {
        window.Android.startZoomClass(JSON.stringify(user));
      }
    } else if (row.isMeeting) {
      window.open('https://zoom.us/j/96909142653?pwd=MUFwTmM0bWxhRzUzcmVGaTRYZmtyZz09', '_blank');
    }
  };

  const external_study_data = useMemo(() => {
    // console.log(dailyExternalStudyScheduleList?.dailyExternalStudySchedule)
    if (dailyExternalStudyScheduleList?.dailyExternalStudySchedule) {
      return dailyExternalStudyScheduleList?.dailyExternalStudySchedule.map((item, key) => {
        const studyData = JSON.parse(item.external_study_data || '{}');
        console.log(studyData, dailyExternalStudyScheduleList?.dailyExternalStudySchedule, 'stu');
        const external_student = item?.external_user_study;
        let stage_completed_student = 0;
        if (external_student) {
          stage_completed_student = external_student.filter((item) => item.status == 1).length;
        }
        return {
          key: item.idx,
          name: item.idx,
          student_detail: external_student,
          student_completed: stage_completed_student,
          article_id: item?.external_study_idx,
          date: item.type === 'levelup' ? (studyData.bookreport ? 'LevelUp - Book Report' : 'LevelUp') : item.start_date || 'All day',
          class_idx: item?.assigned_external_study_group?.class?.idx,
          type: item.type,
          title_type: studyData?.type || 'ExternalStudy',
          title_type_str: studyData?.type_text || 'ExternalStudy',
          title: studyData.title || '',
          studyItem: item,
          group_code: item?.__typename,
          status: item?.is_done,
          percent: item?.is_done,
          assigned_external_study_group: item?.assigned_external_study_group?.idx,
        };
      });
    } else {
      return [];
    }
  }, [dailyExternalStudyScheduleList]);
  // console.log(external_study_data,'external_study_data')
  const external_study_data_for_student = useMemo(() => {
    if (dailyExternalStudyScheduleForStudent?.dailyExternalStudyScheduleForStudent) {
      return dailyExternalStudyScheduleForStudent?.dailyExternalStudyScheduleForStudent.map((item, key) => {
        const studyData = JSON.parse(item.external_study_data || '{}');
        const external_student = item?.external_user_study;
        let stage_completed_student = 0;
        if (external_student) {
          stage_completed_student = external_student.filter((item) => item.status == 1).length;
        }
        // console.log('studyData', studyData);
        return {
          key: item.idx,
          name: item.idx,
          student_detail: external_student,
          student_completed: stage_completed_student,
          article_id: item?.external_study_idx,
          // date: item.start_date || 'All day',
          date: 'New_O_Matic',
          class_idx: item?.assigned_external_study_group?.class?.idx,
          type: 'newsomatic-assign',
          title_type: studyData?.type || 'ExternalStudy',
          title_type_str: studyData?.type_text || 'ExternalStudy',
          title: studyData?.editionId || '',
          studyItem: item,
          group_code: item?.__typename,
          status: item?.is_done,
          percent: item?.is_done,
          assigned_external_study_group: item?.assigned_external_study_group?.idx,
        };
      });
    } else {
      return [];
    }
  }, [dailyExternalStudyScheduleForStudent]);

  const study_data = useMemo(() => {
    if (dailyStudyScheduleList) {
      const isSmarteclass = isPturn(defaultClass) || defaultClass === 'creo' || defaultClass === 'lucid';
      return dailyStudyScheduleList?.dailyStudySchedule.map((item, key) => {
        const isComplete = item?.book_lesson?.stage?.every(
          (stageItem) => stageItem.is_complete && stageItem?.user_lesson_ids?.length > 0 && stageItem?.user_lesson_ids.includes(item.idx),
        );
        const title = isSmarteclass
          ? item?.book_lesson?.title ||
            `${item?.book_lesson?.book?.book_sub[0]?.title} Unit${item?.book_lesson?.unit_no} Lesson${item?.book_lesson?.day_no}`
          : `${item.lesson_code} / ${item?.book_lesson?.title}`;
        if (item?.lesson_code.startsWith('PM-')) {
          let pmBookLevel = item?.book_lesson?.book?.book_sub[0]?.short_title.split(' ')[1];
          return {
            key,
            name: item?.lesson_code + key,
            day: item.study_date,
            //date: item.begin_time || 'All day',
            date: 'Homework',
            title,
            status: item.stat === '1' ? 1 : 4,
            percent: isComplete,
            studyItem: item,
            type: 'study',
            link_url: item.lesson_code
              ? `/pm/${pmBookLevel}/unit/${item?.book_lesson?.unit_name}?lesson_code=${item.lesson_code}&user_lesson_idx=${item.idx}&removeHeader=true`
              : undefined,
            study_complete: isComplete,
          };
        }
        return {
          key,
          name: item?.lesson_code + key,
          day: item.study_date,
          //date: item.begin_time || 'All day',
          date: 'Homework',
          title,
          status: item.stat === '1' ? 1 : 4,
          percent: isComplete,
          studyItem: item,
          type: 'study',
          link_url: item.lesson_code ? `${STUDY_TOOL_HOST}/${item.lesson_code}?user_lesson_idx=${item.idx}&token=${culp_token}` : undefined,
          study_complete: isComplete,
        };
      });
    }

    return [];
  }, [dailyStudyScheduleList, defaultClass]);

  const test_data = useMemo(() => {
    if (dailyTestScheduleList) {
      return dailyTestScheduleList.dailyTestSchedule.map((item, key) => {
        const test_url = `/test/new/${item.idx}/take`;
        const userIdx = data?.me?.idx;
        console.log('item?.__typename', item?.__typename);
        return {
          key,
          name: `test_${key}`,
          day: item.DateOnly,
          title: item?.title,
          percent: false,
          status: item?.assigned_test_users?.filter((val) => val.user_idx === parseInt(userIdx))[0].status,
          studyItem: item,
          link_url: test_url,
          type: 'test',
          group_code: item?.__typename,
        };
      });
    }

    return [];
  }, [dailyTestScheduleList, data?.me?.idx]);

  const test_data_groupdata = useMemo(() => {
    if (dailyTestGrpupScheduleList) {
      return dailyTestGrpupScheduleList?.dailyTestGrpupScheduleList?.map((item, key) => {
        const name = item?.daily_test_group?.name.replace(/\s/g, '');
        const test_url = `/daily/test/${item?.daily_test_group?.name}/${item?.idx}/quiz`;
        const userIdx = data?.me?.idx;

        return {
          key,
          name: `test_group_${key}`,
          day: item.begin_date,
          title: item?.daily_test_group?.name,
          percent: false,
          // status: item?.assigned_test_users?.filter((val) => val.user_idx === parseInt(userIdx))[0].status,
          // studyItem: item,
          status: item?.done_status === true ? 1 : 0,
          link_url: test_url,
          type: 'test_group',
          group_code: item?.__typename,
          code: item?.daily_test_group?.code,
          group_idx: item?.daily_test_group?.idx,
        };
      });
    }
    return [];
  }, [dailyTestGrpupScheduleList, data?.me?.idx]);

  const ai_topic_data = useMemo(() => {
    if (dailyAiTopicStudentScheduleList) {
      return dailyAiTopicStudentScheduleList.dailyAiTopicStudentSchedule.map((item, key) => {
        let dateData = JSON.parse(item?.ai_topic_date_assignment?.data || '{}');
        let topicData = JSON.parse(item?.ai_topic?.data || '{}');
        const title = (
          <>
            <span style={{ fontWeight: 'bold' }}>{item?.class?.name}</span> /{' '}
            {dateData?.revisedPrompt || topicData?.book?.title || item?.ai_topic.topic}
          </>
        );
        return {
          key,
          name: `ai_topic_${key}`,
          day: item.ai_topic_date_assignment.start_date,
          date: item.type === 'ai-topic' ? 'AI Topic' : item.type === 'ai-talking-tutor' ? 'Speaking Coach' : 'Book Report',
          title,
          // title: dateData?.revisedPrompt || item?.ai_topic.topic,
          percent: false,
          status: item.status,
          studyItem: item,
          link_url: null,
          type: item.type,
          group_code: item?.__typename,
        };
      });
    }
    return [];
  }, [dailyAiTopicStudentScheduleList, data?.me?.idx]);

  const book_data = useMemo(() => {
    if (dailyBookScheduleList) {
      return dailyBookScheduleList.dailyBookSchedule.map((item, key) => {
        const userIdx = data?.me?.idx;
        return {
          key,
          name: key,
          day: item.DateOnly,
          title: item?.book_lesson.title,
          status: item?.is_done,
          percent: item?.is_done,
          // status: item?.assigned_test_users?.filter((val) => val.user_idx === parseInt(userIdx))[0].status,
          studyItem: item,
          link_url: item.book_lesson
            ? `https://culp-elibrary-lms.cloubot.com/book/${item.book_lesson.code}?token=${token}&assigned_elibrary_idx=${item.idx}&root=1`
            : undefined,
          type: 'book',
          group_code: 'AssignedBooks',
        };
      });
    }

    return [];
  }, [dailyBookScheduleList, data?.me?.idx]);

  const phonics_book_data = useMemo(() => {
    if (dailyPhonicsScheduleList) {
      let culptoken = localStorage.getItem('culp_token');
      let accesstoken = JSON.parse(culptoken).accessToken;
      return dailyPhonicsScheduleList.AssignedPhonicsDailySchedule.map((item, key) => {
        let booktype =
          item.book_idx.split('-')[1].split('L')[1].charAt(0) + item.book_idx.split('-')[1].split('L')[1].charAt(1).toLowerCase();
        let unitnumber = item.book_idx.split('-')[1].split('L')[1].split('U')[1];
        const userIdx = data?.me?.idx;
        let username = data?.me.name;
        let bookcodepart = item.book_idx.split('');
        let lessonnumber = bookcodepart[bookcodepart.length - 1];
        return {
          key: key,
          name: item?.book_idx + key,
          day: item.phonics_start_date.split('T')[0],
          title: item.unit_title + ' : ' + item.book_idx,
          percent: false,
          status: item.phonics_stages == 'complete' ? 2 : 0,
          studyItem: [],
          link_url: `https://epublic-phonics.cloubot.com/login?id=${userIdx}&token=${accesstoken}&redirect=book/${booktype}/unit/${unitnumber}&lesson=${lessonnumber}&study_idx=${item.idx}`,
          type: 'test',
          group_code: item?.type === 'SW' ? 'Starwords' : 'Phonics',
        };
      });
    }
    return [];
  }, [dailyPhonicsScheduleList, data?.me?.idx]);

  const offline_book_data = useMemo(() => {
    if (dailyOfflineScheduleList) {
      const userIdx = data?.me?.idx;
      let username = data?.me.name;
      return dailyOfflineScheduleList.AssignedOfflineDailySchedule.map((item, key) => {
        return {
          key,
          name: item?.book_idx + key,
          day: item.offline_start_date.split('T')[0],
          title: item.booktitle + ' : ' + item.book_idx,
          percent: false,
          status: 0,
          studyItem: [item.book_idx, item.offline_start_date.split('T')[0], item.user_idx, item.offline_syllabus_add_syllabus_idx],
          link_url: '',
          type: 'offline',
          group_code: 'Offline',
        };
      });
    }
    return [];
  }, [dailyOfflineScheduleList, data?.me?.idx]);

  const zoom_data = useMemo(() => {
    if (dailyZoomScheduleList) {
      return dailyZoomScheduleList.dailyZoomSchedule.map((item, key) => {
        const link_url = JSON.parse(item.meeting_data).join_url;
        return {
          key,
          name: key,
          day: item.DateOnly,
          //title: item.title,
          title: item.start_time,
          percent: 'zoom',
          studyItem: item,
          link_url: link_url ? link_url : undefined,
          type: 'zoom',
          group_code: item?.__typename,
        };
      });
    }

    return [];
  }, [dailyZoomScheduleList]);

  function info(status) {
    if (status === 'end') {
      Modal.info({
        title: '이미 완료한 테스트입니다.',
        onOk() {},
      });
    } else if (status === 'no') {
      Modal.info({
        title: '테스트 기간이 아닙니다.',
        onOk() {},
      });
    } else if (status === 'endbookreport') {
      Modal.info({
        title: '이미 완료한 과제입니다.',
        onOk() {},
      });
    }
  }

  const onChange = (text) => {
    setOfflineText(text);
  };

  return (
    <>
      <Title className="studystatus-haeding" level={5} style={{ paddingLeft: '20px' }}>
        <div className="studystatus">Study Status</div>{' '}
        <div className="studystatus-date"> | {moment(selectedDate).format('YYYY-MM-DD')}</div>
      </Title>
      <TodayTable
        pagination={false}
        showHeader={false}
        columns={columns}
        rowKey="name"
        dataSource={[
          ...study_data,
          ...test_data,
          ...zoom_data,
          ...book_data,
          ...phonics_book_data,
          ...Fake_data,
          ...offline_book_data,
          ...external_study_data,
          ...external_study_data_for_student,
          ...ai_topic_data,
          // ...ai_talking,
          ...test_data_groupdata,
        ]}
        scroll={{ x: 476, y: 265 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onHandleRowClick(record, rowIndex, user),
          };
        }}
        size="small"
      />
      <Modal
        title="Today's Homework"
        centered
        visible={visible}
        width={700}
        onSave={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Editor
          editorState={OfflineText}
          style={{ width: '100%' }}
          rootStyle={{ height: 'calc(100% - 50px)', width: '100%', minHeight: 300 }}
          editorStyle={{ backgroundColor: '#fff', minHeight: 120 }}
          toolbarClassName="hide-print"
          wrapperClassName="essay-wrapper"
          onEditorStateChange={onChange}
          toolbar={{
            options: [],
          }}
          readOnly={isReadOnly}
        />
      </Modal>
      {newsOMaticData !== null && (
        <NewsOMaticStudyButton visible={newsOMaticData !== null} setData={setNewsOMaticData} data={newsOMaticData} />
      )}
      {newsOMaticDataAssign !== null && (
        <NewsOMaticStudyButton
          visible_assign={newsOMaticDataAssign !== null}
          setData={setNewsOMaticDataAssign}
          data={newsOMaticDataAssign}
        />
      )}
      {bookrData !== null && <BookrOpenModal visible={true} url={bookrData?.url} handleClose={() => setBookrData(null)} />}
      {openAiModal && (
        <OpenAiModal
          visible={openAiModal}
          topic={topic}
          userTopicId={userTopicId}
          userTopicStatus={userTopicStatus}
          handleCancel={() => {
            setOpenAiModal(false);
            refetchAiTopics();
          }}
        />
      )}
      {viewAnswerModal && (
        <ViewAnswerModal
          visible={viewAnswerModal}
          topic={topic}
          userTopicId={userTopicId}
          topicAnswer={topicAnswer}
          handleCancel={() => {
            setViewAnswerModal(false);
            refetchAiTopics();
          }}
        />
      )}
      <PhonicsNoticePopupForStudents visible={phonicsVisible} />
      <StudyNoticePopupForStudents visible={studyVisible} />
    </>
  );
}

export default TodayStudy;
